import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaEye, FaRegEdit, FaSearch, FaTrashAlt } from 'react-icons/fa'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  FormControl,
  Pagination,
  Spinner,
} from 'react-bootstrap'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const FinanceManagement = () => {
  const [financeData, setFinanceData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortColumn, setSortColumn] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(Constants.getAllFinanceEnquiry)
      .then((response) => response.json())
      .then((data) => {
        setFinanceData(data.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching finance data:', error)
        setLoading(false)
      })
  }, [])

  const handleDelete = (id) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this finance data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Make API call to delete finance data by ID
        fetch(`${Constants.deleteFinanceEnquiryById}${id}`, {
          method: 'DELETE',
        })
          .then((response) => response.json())
          .then((data) => {
            // Update the financeData state after successful deletion
            setFinanceData((prevData) => prevData.filter((item) => item._id !== id))
            MySwal.fire('Deleted!', 'Your finance data has been deleted.', 'success')
          })
          .catch((error) => {
            console.error('Error deleting finance data:', error)
            MySwal.fire('Error!', 'An error occurred while deleting finance data.', 'error')
          })
      }
    })
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
  }

  const filteredFinanceData = financeData.filter((data) =>
    Object.values(data).some(
      (value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )

  const sortedFinanceData = filteredFinanceData.sort((a, b) => {
    const columnA = a[sortColumn]
    const columnB = b[sortColumn]

    if (columnA && columnB) {
      const valueA = typeof columnA === 'string' ? columnA : String(columnA)
      const valueB = typeof columnB === 'string' ? columnB : String(columnB)

      if (sortOrder === 'asc') {
        return valueA.localeCompare(valueB)
      } else {
        return valueB.localeCompare(valueA)
      }
    }

    return 0
  })

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = sortedFinanceData.slice(indexOfFirstItem, indexOfLastItem)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">ENQUIRY MANAGEMENT</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Finance Management</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          ) : (
            <Row className="m-2">
              <Col xs={12}>
                <div className="card custom-shadow rounded-lg border">
                  <div className="card-body">
                    <Row>
                      <Col md={4} xs={12} className="mb-3 text-center">
                        <h3 className="fw-bolder text-info mb-0">{financeData.length}</h3>
                        <p className="text-secondary">Total Financial Enquiry</p>
                      </Col>
                    </Row>
                    <div style={{ width: '30%' }}>
                      <InputGroup className="mb-3">
                        <FormControl
                          placeholder="Search..."
                          aria-label="Search"
                          aria-describedby="basic-addon2"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <InputGroup.Text id="basic-addon2">
                          <FaSearch />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>

                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th onClick={() => handleSort('userId.firstName')}>
                            User
                            {sortColumn === 'userId.firstName' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('cashFinanceLease')}>
                            Payment Mode
                            {sortColumn === 'cashFinanceLease' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('creditPreApproval')}>
                            Credit Pre Approval
                            {sortColumn === 'creditPreApproval' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('createdAt')}>
                            Enquiry Date
                            {sortColumn === 'createdAt' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th>With / Without Data</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((data) => (
                          <tr key={data._id}>
                            <td>
                              {data?.userId?.firstName} {data?.userId?.lastName}
                            </td>
                            <td>{data?.cashFinanceLease}</td>
                            <td>{data?.creditPreApproval}</td>
                            <td>{new Date(data.createdAt).toLocaleDateString()}</td>
                            <td>{data?.withData ? 'With Data' : 'Without Data'}</td>
                            <td>
                              <Link to={`/viewFinance/${data._id}`}>
                                <Button variant="outline-info" size="sm">
                                  <FaEye />
                                </Button>
                              </Link>
                              <Button
                                variant="outline-danger"
                                size="sm"
                                className="ms-2"
                                onClick={() => handleDelete(data?._id)}
                              >
                                <FaTrashAlt />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination>
                      {Array.from({
                        length: Math.ceil(sortedFinanceData.length / itemsPerPage),
                      }).map((_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index + 1 === currentPage}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                    </Pagination>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  )
}

export default FinanceManagement
