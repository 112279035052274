import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Form, Button, Spinner } from 'react-bootstrap'
import { SketchPicker } from 'react-color'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Constants } from 'src/Constants'
import ReactQuill from 'react-quill'

const MySwal = withReactContent(Swal)

const EditVehicleType = () => {
  const { id } = useParams()

  const [makeDetails, setMakeDetails] = useState({
    name: '',

    status: 'active',
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(Constants.getVehicleTypeById + id)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch vehicle type details')
        }
        return response.json()
      })
      .then((data) => {
        setMakeDetails({
          name: data.name || '',

          status: data.status || 'active',
        })
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching make details:', error)
        // Handle error, show error message, etc.
      })
  }, [id])

  const handleSave = () => {
    const requestData = {
      name: makeDetails.name,
      status: makeDetails.status,
    }

    fetch(Constants.updateVehicleType + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (response.ok) {
          MySwal.fire('Success!', 'Vehicle Type details updated successfully.', 'success')
        } else {
          throw new Error('Failed to update Vehicle Type details.')
        }
      })
      .catch((error) => {
        console.error('Error updating Vehicle Type details:', error)
        MySwal.fire('Error', 'Failed to update Vehicle Type details.', 'error')
      })
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">EDIT VEHICLE TYPES</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Edit Vehicle Type</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <h5 className="h6 text-uppercase mb-3">Vehicle Type Information</h5>
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center vh-100">
                      <Spinner animation="border" role="status">
                        <span className="sr-only"></span>
                      </Spinner>
                    </div>
                  ) : (
                    <Form className="row">
                      <Form.Group className="mb-3" controlId="makeName">
                        <Form.Label>Vehicle Type Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Vehicle Type Name"
                          value={makeDetails.name}
                          onChange={(e) => setMakeDetails({ ...makeDetails, name: e.target.value })}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={makeDetails.status}
                          onChange={(e) =>
                            setMakeDetails({ ...makeDetails, status: e.target.value })
                          }
                        >
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </Form.Control>
                      </Form.Group>

                      <div className="text-end">
                        {/* <Button variant="outline-info" className="min-btn m-2">
                        Cancel
                      </Button> */}
                        <Button variant="info" className="min-btn m-2" onClick={handleSave}>
                          Update Vehicle Type
                        </Button>
                      </div>
                    </Form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
EditVehicleType.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image', 'video'],
    ['clean'],
    ['code-block'],
  ],
}

EditVehicleType.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'link',
  'image',
  'video',
  'code-block',
]
export default EditVehicleType
