import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Spinner, Breadcrumb, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Constants } from 'src/Constants';


const MySwal = withReactContent(Swal);

const ViewDealer = () => {
  const { id } = useParams();
  const [dealerInfo, setDealerInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDealerInfo = async () => {
      try {
        const response = await fetch(Constants.getDealerById + id);
        if (!response.ok) {
          throw new Error('Failed to fetch dealer info');
        }
        const data = await response.json();
        setDealerInfo(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching dealer info:', error);
        setLoading(false);
      }
    };

    fetchDealerInfo();
  }, [id]);

  const handleGoBack = () => {
    window.history.back();
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">VIEW DEALER</h4>
                <div className="page-title-right">
                  <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/dashboard' }}>Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>View Dealer</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </Col>
          </Row>

          {loading ? (
            <Row className="justify-content-center mt-4">
              <Col xs="auto">
                <Spinner animation="border" variant="info" />
              </Col>
            </Row>
          ) : (
            <Row className="m-2">
              <Col xs={12}>
                <Card className="custom-shadow rounded-lg border">
                  <Card.Body>
                    <h5 className="card-title">Dealer Information</h5>
                    <Table striped bordered hover>
                      <tbody>
                        <tr>
                          <td>First Name:</td>
                          <td>{dealerInfo?.firstName}</td>
                        </tr>
                        <tr>
                          <td>Last Name:</td>
                          <td>{dealerInfo?.lastName}</td>
                        </tr>
                        <tr>
                          <td>Email:</td>
                          <td>{dealerInfo?.email}</td>
                        </tr>
                        <tr>
                          <td>Mobile:</td>
                          <td>{dealerInfo?.mobile}</td>
                        </tr>
                        <tr>
                          <td>Company:</td>
                          <td>{dealerInfo?.company}</td>
                        </tr>
                        <tr>
                          <td>Website:</td>
                          <td>{dealerInfo?.website}</td>
                        </tr>
                        <tr>
                          <td>Business Time:</td>
                          <td>{dealerInfo?.businessTime}</td>
                        </tr>
                        <tr>
                          <td>Car Company:</td>
                          <td>{dealerInfo?.carCompany}</td>
                        </tr>
                        <tr>
                          <td>Company Details:</td>
                          <td>{dealerInfo?.companyDetails}</td>
                        </tr>
                        <tr>
                          <td>City:</td>
                          <td>{dealerInfo?.city}</td>
                        </tr>
                        <tr>
                          <td>Country:</td>
                          <td>{dealerInfo?.country}</td>
                        </tr>
                        <tr>
                          <td>ZIP:</td>
                          <td>{dealerInfo?.zip}</td>
                        </tr>
                        <tr>
                          <td>Status:</td>
                          <td>{dealerInfo?.status}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <Button variant="secondary" onClick={handleGoBack}>Go Back</Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  );
}

export default ViewDealer;
