import React, { useState, useEffect } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Constants } from 'src/Constants'

const Dashboard = () => {
  const [vehicleTypesCount, setVehicleTypesCount] = useState(0)
  const [totalMakesCount, setTotalMakesCount] = useState(0)
  const [carsCount, setCarsCount] = useState(0)
  const [usersCount, setUserCount] = useState(0)
  const [dealersCount, setDealersCount] = useState(0)
  const [financeCount, setFinanceCount] = useState(0)

  useEffect(() => {
    fetch(Constants.getAllVehicleTypes)
      .then((response) => response.json())
      .then((data) => setVehicleTypesCount(data.length))
      .catch((error) => console.error('Error fetching Vehicle Types:', error))

    fetch(Constants.getAllMakes)
      .then((response) => response.json())
      .then((data) => setTotalMakesCount(data.length))
      .catch((error) => console.error('Error fetching Total Makes:', error))

    fetch(Constants.getAllCar)
      .then((response) => response.json())
      .then((data) => setCarsCount(data.length))
      .catch((error) => console.error('Error fetching Cars:', error))

    fetch(Constants.getAllUsers)
      .then((response) => response.json())
      .then((data) => setUserCount(data.length))
      .catch((error) => console.error('Error fetching Cars:', error))
    fetch(Constants.getAllDealers)
      .then((response) => response.json())
      .then((data) => setDealersCount(data.length))
      .catch((error) => console.error('Error fetching Dealers:', error))
    fetch(Constants.getAllFinanceEnquiry)
      .then((response) => response.json())
      .then((data) => setFinanceCount(data.data.length))
      .catch((error) => console.error('Error fetching Dealers:', error))
  }, [])
  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">DASHBOARD </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="text-info breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-3">
                  <div className="card-body">
                    <Row className="mt-4">
                      <Col>
                        <div className="dashboard-card ">
                          <div>
                            <h5> Vehicle Types</h5>
                            <div className="text-center">
                              <b style={{ color: 'black' }}>{vehicleTypesCount}</b>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <div>
                            <h5>Total Makes</h5>
                            <div className="text-center">
                              <b style={{ color: 'black' }}> {totalMakesCount}</b>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <div>
                            <h5>Cars</h5>
                            <div className="text-center">
                              <b style={{ color: 'black' }}> {carsCount} </b>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <div className="dashboard-card">
                          <div>
                            <h5>Users</h5>
                            <div className="text-center">
                              <b style={{ color: 'black' }}> {usersCount} </b>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <div>
                            <h5>Dealers</h5>
                            <div className="text-center">
                              <b style={{ color: 'black' }}> {dealersCount} </b>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <div>
                            <h5>Financial Enquiry</h5>
                            <div className="text-center">
                              <b style={{ color: 'black' }}> {financeCount}</b>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-3">
                  <div className="card-body">
                    <Row className="mt-4">
                      <Col>
                        <div className="dashboard-card">
                          <Card.Body>
                            <h4>Vehicle Type Management</h4>
                            <Link to="/vehicleType" className="btn btn-primary">
                              Manage Vehicle Type
                            </Link>
                          </Card.Body>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <Card.Body>
                            <h4>Make List Management</h4>
                            <Link to={'/productList'} className="btn btn-primary">
                              Manage Makes
                            </Link>
                          </Card.Body>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <Card.Body>
                            <h4>Car Listing Management</h4>
                            <Link to={'/carList'} className="btn btn-primary">
                              Manage Car Listing
                            </Link>
                          </Card.Body>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col>
                        <div className="dashboard-card">
                          <Card.Body>
                            <h4>User Management</h4>
                            <Link to={'/userList'} className="btn btn-primary">
                              Manage Makes
                            </Link>
                          </Card.Body>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <Card.Body>
                            <h4>Dealer Management</h4>
                            <Link to={'/customerList'} className="btn btn-primary">
                              Manage Makes
                            </Link>
                          </Card.Body>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <Card.Body>
                            <h5>Financial Management</h5>
                            <Link to={'/financeManagement'} className="btn btn-primary">
                              Manage Finance
                            </Link>
                          </Card.Body>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
