import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { ImCross } from 'react-icons/im'
import Swal from 'sweetalert2'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import withReactContent from 'sweetalert2-react-content'
import { SketchPicker } from 'react-color'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const AddNewProduct = () => {
  const [makeName, setMakeName] = useState('')
  const [status, setStatus] = useState('active')
  const [primaryColor, setPrimaryColor] = useState('#ffffff')
  const [secondaryColor, setSecondaryColor] = useState('#000000')
  const [bannerImage, setBannerImage] = useState(null)
  const [heading, setHeading] = useState('')
  const [description, setDescription] = useState('')
  const [brandIcon, setBrandIcon] = useState(null)
  const [image, setImage] = useState(null)
  const [vehicleTypes, setVehicleTypes] = useState([])
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState([])
  const [vehicleTypesDetails, setVehicleTypesDetails] = useState([])
  const [checkedVehicleTypes, setCheckedVehicleTypes] = useState([])
  // console.log(selectedVehicleTypes)
  useEffect(() => {
    const fetchMakesAndVehicleTypes = async () => {
      try {
        const vehicleTypesResponse = await fetch(Constants.getAllVehicleTypes)
        if (!vehicleTypesResponse.ok) {
          throw new Error(`HTTP error! Status: ${vehicleTypesResponse.status}`)
        }
        const vehicleTypesData = await vehicleTypesResponse.json()

        const activeVehicleTypes = vehicleTypesData.filter((type) => type.status === 'active')
        const vehicleTypeNames = activeVehicleTypes.map((type) => {
          return { name: type.name, id: type._id }
        })
        setVehicleTypes(vehicleTypeNames)
      } catch (error) {
        console.error('Error fetching data:', error.message)
      }
    }

    fetchMakesAndVehicleTypes()
  }, [])

  // const hexToCssFilter = (hexColor) => {
  //   const rgbColor = hexToRgb(hexColor)
  //   const brightness = (rgbColor.r * 0.299 + rgbColor.g * 0.587 + rgbColor.b * 0.114) / 255
  //   const saturation =
  //     (Math.max(rgbColor.r, rgbColor.g, rgbColor.b) -
  //       Math.min(rgbColor.r, rgbColor.g, rgbColor.b)) /
  //     255
  //   const hueRotate = 0

  //   return `hue-rotate(${hueRotate}deg) saturate(${saturation * 100}%) brightness(${
  //     brightness * 100
  //   }%)`
  // }

  // const hexToRgb = (hex) => {
  //   hex = hex.replace(/^#/, '')

  //   const bigint = parseInt(hex, 16)
  //   const r = (bigint >> 16) & 255
  //   const g = (bigint >> 8) & 255
  //   const b = bigint & 255

  //   return { r, g, b }
  // }
  const handleVehicleTypeCheckboxChange = (typeName) => {
    if (selectedVehicleTypes.includes(typeName.name)) {
      setSelectedVehicleTypes(
        selectedVehicleTypes.filter((selectedType) => selectedType !== typeName.name),
      )
    } else {
      setVehicleTypesDetails([
        ...vehicleTypesDetails,
        {
          vehicleTypeName: typeName.id,
          vehicleTypeHeading: '',
          backgroundImg: '',
          iconImg: '',
          bannerImg: '',
        },
      ])
      setSelectedVehicleTypes([...selectedVehicleTypes, typeName.name])
    }
    if (selectedVehicleTypes.includes(typeName.name)) {
      setSelectedVehicleTypes(
        selectedVehicleTypes.filter((selectedType) => selectedType !== typeName.name),
      )
    } else {
      setSelectedVehicleTypes([...selectedVehicleTypes, typeName.name])
    }
  }
  // console.log(selectedVehicleTypes)
  const handleSave = async () => {
    try {
      // const primaryColorCssFilter = hexToCssFilter(primaryColor)
      // const secondaryColorCssFilter = hexToCssFilter(secondaryColor)
      const formData = new FormData()
      formData.append('name', makeName)
      formData.append('primaryColor', primaryColor)
      formData.append('secondaryColor', secondaryColor)
      formData.append('status', status)
      formData.append('heading', heading)
      formData.append('description', description)
      if (bannerImage) {
        if (bannerImage instanceof FileList && bannerImage.length > 0) {
          formData.append('bannerImage', bannerImage[0], bannerImage[0].name)
        } else {
          formData.append('bannerImage', bannerImage, bannerImage.name)
        }
      } else {
        formData.append('bannerImage', new Blob(), 'emptyFile.txt')
      }

      if (brandIcon) {
        formData.append('brandIcon', brandIcon[0], brandIcon[0].name)
      } else {
        formData.append('brandIcon', new Blob(), 'emptyFile.txt')
      }

      if (image) {
        formData.append('image', image[0], image[0].name)
      } else {
        formData.append('image', new Blob(), 'emptyFile.txt')
      }

      selectedVehicleTypes.forEach((type) => {
        formData.append('vehicleType', type)
      })
      formData.append('vehicleTypesDeails', JSON.stringify(vehicleTypesDetails))

      const response = await fetch(Constants.addNewMake, {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        MySwal.fire('Saved!', 'Make information has been saved.', 'success')

        setMakeName('')
        setPrimaryColor('#ffffff')
        setSecondaryColor('#000000')
        setBannerImage(null)
        setStatus('active')
        setHeading('')
        setDescription('')
        setBrandIcon(null)
        setImage(null)
        setSelectedVehicleTypes([])
        setVehicleTypesDetails([])
      } else {
        throw new Error('Failed to save make information.')
      }
    } catch (error) {
      console.error('Error:', error)
      MySwal.fire('Error', 'Failed to save make information.', 'error')
    }
  }
  const handleVehicleTypeDetailsChange = (typeName, field, value) => {
    const temp = [...vehicleTypesDetails]
    const foundItem = temp.find((item) => item.vehicleTypeName === typeName.id)
    foundItem[field] = value
    setVehicleTypesDetails(temp)
  }

  const handleVehicleTypeImageChange = async (typeName, field, file) => {
    try {
      const formData = new FormData()
      formData.append('file', file)

      const response = await fetch(Constants.postImageFile, {
        method: 'POST',
        body: formData,
      })

      if (!response.ok) {
        throw new Error('Failed to upload image')
      }

      const data = await response.json()
      const temp = [...vehicleTypesDetails]
      const foundItem = temp.find((item) => item.vehicleTypeName === typeName.id)
      foundItem[field] = data.file._id
      setVehicleTypesDetails(temp)
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">MAKE LISTINGS</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Add New Make</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <h5 className="h6 text-uppercase mb-3">Make Information</h5>
                  <Form className="row">
                    <Form.Group className="mb-3" controlId="makeName">
                      <Form.Label>Make Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Make Name"
                        value={makeName}
                        onChange={(e) => setMakeName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="col-lg-6 mb-3" controlId="primaryColor">
                      <Form.Label>Primary Color</Form.Label>
                      <SketchPicker
                        color={primaryColor}
                        onChange={(color) => setPrimaryColor(color.hex)}
                      />
                    </Form.Group>

                    <Form.Group className="col-lg-6 mb-3" controlId="secondaryColor">
                      <Form.Label>Secondary Color</Form.Label>
                      <SketchPicker
                        color={secondaryColor}
                        onChange={(color) => setSecondaryColor(color.hex)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="heading">
                      <Form.Label>Heading</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading"
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="description">
                      <Form.Label> Description</Form.Label>
                      <ReactQuill
                        theme="snow"
                        value={description}
                        onChange={setDescription}
                        modules={{
                          toolbar: [
                            [{ header: '1' }, { header: '2' }, { font: [] }],
                            [{ size: [] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [
                              { list: 'ordered' },
                              { list: 'bullet' },
                              { indent: '-1' },
                              { indent: '+1' },
                            ],
                            ['link', 'image', 'video'],
                            ['clean'],
                          ],
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="bannerImage">
                      <Form.Label>Banner Image</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files
                          if (file) {
                            setBannerImage(file)
                          }
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="brandIcon">
                      <Form.Label>Brand Icon</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files
                          if (file) {
                            setBrandIcon(file)
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="image">
                      <Form.Label>Background Image</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files
                          if (file) {
                            setImage(file)
                          }
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="status">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Form.Control>
                    </Form.Group>

                    {/* start */}

                    <Form.Group className="mb-3 mt-3" controlId="vehicleTypes">
                      <Form.Label>Vehicle Types (Select From The list)</Form.Label>
                      <ul className="nav nav-pills mb-3 vehith" id="pills-tab" role="tablist">
                        {vehicleTypes.map((typeName, index) => (
                          <li className="nav-item" key={index}>
                            <button
                              className={`nav-link ${index === 0 ? 'active ' : ''}`}
                              style={{
                                borderRadius: '50px',
                                color: selectedVehicleTypes.includes(typeName.name)
                                  ? '#fff'
                                  : '#3c4b64',
                                backgroundColor: selectedVehicleTypes.includes(typeName.name)
                                  ? 'green'
                                  : '#f1f1f1',
                                border: '1px solid',
                                borderColor: selectedVehicleTypes.includes(typeName.name)
                                  ? 'green'
                                  : '#f1f1f1',
                                marginRight: '10px',
                                marginBottom: '12px',
                                transition: 'all',
                              }}
                              id={`pills-${typeName.name}-tab`}
                              data-bs-toggle="pill"
                              data-bs-target={`#pills-${typeName.name}`}
                              type="button"
                              role="tab"
                              aria-controls={`pills-${typeName.name}`}
                              aria-selected={index === 0 ? 'true' : 'false'}
                            >
                              {typeName.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        {vehicleTypes.map((typeName, index) => (
                          <div
                            className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                            id={`pills-${typeName.name}`}
                            role="tabpanel"
                            aria-labelledby={`pills-${typeName.name}-tab`}
                            key={index}
                          >
                            <div className="d-flex flex-wrap">
                              <Form.Check
                                type="checkbox"
                                label={typeName.name}
                                checked={selectedVehicleTypes.includes(typeName.name)}
                                onChange={() => handleVehicleTypeCheckboxChange(typeName)}
                              />
                            </div>
                            <div style={{ minHeight: '150px' }}>
                              {selectedVehicleTypes.includes(typeName.name) && (
                                <div className="">
                                  <div className="mb-3">
                                    <Form.Label>Vehicle Type Heading</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Vehicle Type Heading"
                                      onChange={(e) =>
                                        handleVehicleTypeDetailsChange(
                                          typeName,
                                          'vehicleTypeHeading',
                                          e.target.value.replace(/:/g, ''), // Replace all colons with an empty string
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Form.Label>Background Image</Form.Label>
                                    {/* field name for image upload backgroundImg */}
                                    <Form.Control
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleVehicleTypeImageChange(
                                          typeName,
                                          'backgroundImg',
                                          e.target.files[0],
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Form.Label>Icon Image</Form.Label>
                                    {/* field name for image upload iconImg */}
                                    <Form.Control
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleVehicleTypeImageChange(
                                          typeName,
                                          'iconImg',
                                          e.target.files[0],
                                        )
                                      }
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <Form.Label>Banner Image</Form.Label>
                                    {/* field name for image upload iconImg */}
                                    <Form.Control
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleVehicleTypeImageChange(
                                          typeName,
                                          'bannerImg',
                                          e.target.files[0],
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Form.Group>

                    <div className="text-end">
                      <Button
                        // variant="info"
                        className="min-btn m-2"
                        onClick={handleSave}
                        style={{
                          backgroundColor: '#3c4b64',
                          color: 'white',
                          transition: 'transform 0.2s ease-in-out',

                          ':hover': {
                            transform: 'scale(1.1)',
                          },
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddNewProduct

