import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Form, Button, Spinner } from 'react-bootstrap'
import { SketchPicker } from 'react-color'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const EditProduct = () => {
  const { id } = useParams()
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState([])
  const [makeDetails, setMakeDetails] = useState({
    name: '',
    heading: '',
    description: '',
    brandIcon: null,
    primaryColor: '#ffffff',
    secondaryColor: '#000000',
    bannerImage: null,
    image:null,
    status: 'active',
  })
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchMakesAndVehicleTypes = async () => {
      try {
        // Fetch vehicle types
        const vehicleTypesResponse = await fetch(Constants.getAllVehicleTypes);
        if (!vehicleTypesResponse.ok) {
          throw new Error(`HTTP error! Status: ${vehicleTypesResponse.status}`);
        }
        const vehicleTypesData = await vehicleTypesResponse.json();
  
        if (Array.isArray(vehicleTypesData)) {
          const activeVehicleTypes = vehicleTypesData.filter((type) => type.status === 'active');
          const vehicleTypeNames = activeVehicleTypes.map((type) => type.name);
          setVehicleTypes(vehicleTypeNames);
        } else {
          console.error('Invalid vehicle types data:', vehicleTypesData);
        }
  
        // Fetch make details
        const makeResponse = await fetch(Constants.getMakeByID + id);
        if (!makeResponse.ok) {
          throw new Error(`HTTP error! Status: ${makeResponse.status}`);
        }
        const makeData = await makeResponse.json();
  
        setMakeDetails({
          name: makeData.name,
          heading: makeData.heading,
          description: makeData.description,
          brandIcon: makeData.brandIcon,
          primaryColor: makeData.primaryColor,
          secondaryColor: makeData.secondaryColor,
          bannerImage: makeData.bannerImage,
          image : makeData.image,
          status: makeData.status,
        });
  
        const vehicleTypeNames = makeData.vehicleType.map((type) => type.name);
        setSelectedVehicleTypes(vehicleTypeNames);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };
  
    fetchMakesAndVehicleTypes();
  }, [id]);
  

  const handleSave = () => {
    const formData = new FormData()
    formData.append('name', makeDetails.name)
    formData.append('heading', makeDetails.heading)
    formData.append('description', makeDetails.description)
    formData.append('primaryColor', makeDetails.primaryColor)
    formData.append('secondaryColor', makeDetails.secondaryColor)
    formData.append('status', makeDetails.status)

    if (makeDetails.bannerImage) {
      formData.append('bannerImage', makeDetails.bannerImage)
    }

    if (makeDetails.brandIcon) {
      formData.append('brandIcon', makeDetails.brandIcon)
    }

    if (makeDetails.image) {
      formData.append('image', makeDetails.image)
    }

    selectedVehicleTypes.forEach((type) => {
      formData.append('vehicleTypes', type)
    })

    fetch(Constants.updateMakeByID + id, {
      method: 'PUT',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          MySwal.fire('Success!', 'Make details updated successfully.', 'success')
        } else {
          throw new Error('Failed to update make details.')
        }
      })
      .catch((error) => {
        console.error('Error updating make details:', error)
        MySwal.fire('Error', 'Failed to update make details.', 'error')
      })
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    setMakeDetails({ ...makeDetails, bannerImage: file })
  }

  const handleBrandIconChange = (e) => {
    const file = e.target.files[0]
    setMakeDetails({ ...makeDetails, brandIcon: file })
  }

  const handleBackImageChange = (e) => {
    const file = e.target.files[0]
    setMakeDetails({ ...makeDetails, image: file })
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">EDIT MAKE LISTINGS</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Edit Make</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <h5 className="h6 text-uppercase mb-3">Make Information</h5>
                  {/* {loading ? (
                    <div className="d-flex justify-content-center align-items-center vh-100">
                      <Spinner animation="border" role="status">
                        <span className="sr-only"></span>
                      </Spinner>
                    </div>
                  ) : ( */}
                    <Form className="row">
                      <Form.Group className="mb-3" controlId="makeName">
                        <Form.Label>Make Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Make Name"
                          value={makeDetails.name}
                          onChange={(e) => setMakeDetails({ ...makeDetails, name: e.target.value })}
                        />
                      </Form.Group>
                      <Form.Group className="col-lg-6 mb-3" controlId="primaryColor">
                        <Form.Label>Primary Color</Form.Label>
                        <SketchPicker
                          color={makeDetails.primaryColor}
                          onChangeComplete={(color) =>
                            setMakeDetails({ ...makeDetails, primaryColor: color.hex })
                          }
                        />
                      </Form.Group>

                      <Form.Group className="col-lg-6 mb-3" controlId="secondaryColor">
                        <Form.Label>Secondary Color</Form.Label>
                        <SketchPicker
                          color={makeDetails.secondaryColor}
                          onChangeComplete={(color) =>
                            setMakeDetails({ ...makeDetails, secondaryColor: color.hex })
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="vehicleTypes">
                        <Form.Label>Vehicle Types (Select From The List)</Form.Label>
                        <div className="d-flex flex-wrap">
                          {Array.isArray(vehicleTypes) &&
                            vehicleTypes.map((typeName, index) => (
                              <div key={index} className="me-3 mb-2">
                                <Form.Check
                                  key={index}
                                  type="checkbox"
                                  label={typeName}
                                  checked={selectedVehicleTypes.includes(typeName)}
                                  onChange={() => {
                                    if (selectedVehicleTypes.includes(typeName)) {
                                      setSelectedVehicleTypes(
                                        selectedVehicleTypes.filter(
                                          (selectedType) => selectedType !== typeName,
                                        ),
                                      )
                                    } else {
                                      setSelectedVehicleTypes([...selectedVehicleTypes, typeName])
                                    }
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="heading">
                        <Form.Label>Heading</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Heading"
                          value={makeDetails.heading}
                          onChange={(e) => setMakeDetails({ ...makeDetails, heading: e.target.value })}
                        />
                      </Form.Group>

                      {/* <Form.Group controlId="heading" className="mb-3">
                        <Form.Label> Heading</Form.Label>
                        <ReactQuill
                          value={makeDetails.heading}
                          onChange={(value) =>
                            setMakeDetails((prevDetails) => ({ ...prevDetails, heading: value }))
                          }
                          modules={{
                            toolbar: [
                              [{ header: '1' }, { header: '2' }, { font: [] }],
                              [{ size: [] }],
                              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' },
                              ],
                              ['link', 'image', 'video'],
                              ['clean'],
                            ],
                          }}
                        />
                      </Form.Group> */}

                      <Form.Group controlId="description" className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <ReactQuill
                          value={makeDetails.description}
                          onChange={(value) =>
                            setMakeDetails((prevDetails) => ({
                              ...prevDetails,
                              description: value,
                            }))
                          }
                          modules={{
                            toolbar: [
                              [{ header: '1' }, { header: '2' }, { font: [] }],
                              [{ size: [] }],
                              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' },
                              ],
                              ['link', 'image', 'video'],
                              ['clean'],
                            ],
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="brandIcon">
                        <Form.Label>Brand Icon</Form.Label>
                        <Form.Control
                          type="file"
                          accept="image/*"
                          onChange={handleBrandIconChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="bannerImage">
                        <Form.Label>Banner Image</Form.Label>
                        <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="image">
                        <Form.Label>Background Image</Form.Label>
                        <Form.Control type="file" accept="image/*" onChange={handleBackImageChange} />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={makeDetails.status}
                          onChange={(e) =>
                            setMakeDetails({ ...makeDetails, status: e.target.value })
                          }
                        >
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </Form.Control>
                      </Form.Group>

                      <div className="text-end">
                        <Button variant="info" className="min-btn m-2" onClick={handleSave}>
                          Update Make
                        </Button>
                      </div>
                    </Form>
                  {/*  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditProduct
