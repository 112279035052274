import React from 'react'

import AddNewProject from './views/addNewProject/AddNewProject'
import Login from './views/login/Login.js'
import Dashboard from './views/dashboard/Dashboard'
import ProjectDetails from './views/projectDetails/ProjectDetails.js'
// import Profile from './views/profile/Profile.js';
import CustomerList from './views/customerList/CustomerList.js'
import AddNewCustomer from './views/addNewCustomer/AddNewCustomer.js'
import ProjectList from './views/projectList/ProjectList.js'
import EditCustomer from './views/editCustomer/EditCustomer.js'

import CustomerDetails from './views/customerDetails/CustomerDetails.js'
import Settings from './views/settings/Settings.js'

import AddNewCar from './views/addNewCar/AddNewCar'
import CarList from './views/carList/CarList'
import EditProduct from './views/editProject/EditProject.js'
import EditMakeDetails from './views/editMakeDetails/EditMakeDetails'

import ViewCar from './views/viewCar/ViewCar'
import EditCar from './views/editCar/EditCar'
import VehicleTypeList from './views/vehicleTypeList/VehicleTypeList'
import AddNewVehicleType from './views/addNewVehicleType/AddNewVehicleType'
import EditVehicleType from './views/editVehicleType/EditVehicleType'
import FinanceManagement from './views/financeManagement/FinanceManagement'
import ViewFinance from './views/viewFinance/ViewFinance'
import UserList from './views/userList/UserList'
import UserDetails from './views/userDetails/UserDetails'
import AddNewUser from './views/addNewUser/AddNewUser'
import ViewDealer from './views/viewDealer.js/ViewDealer'
import AboutUsForm from './views/aboutUs/AboutUs'
import PolicyAndServices from './views/policyAndService/PolicyAndServices'
import ContactMessages from './views/contactMessages/ContactMessages'
import AppointmentBooking from './views/appointmentBooking/AppointmentBooking'
import popover from './views/popover/popover'

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/login', name: 'Login', element: Login },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/projectDetails/:id', name: 'ProjectDetails', element: ProjectDetails },
  // { path: '/profile', name: 'Profile', element: Profile },
  { path: '/customerList', name: 'Customer List', element: CustomerList },
  { path: '/addNewCustomer', name: 'Add New Customer', element: AddNewCustomer },
  { path: '/productList', name: 'Project List', element: ProjectList },
  { path: '/addNewProduct', name: 'Add New Project', element: AddNewProject },
  { path: '/editCustomer', name: 'Edit Customer', element: EditCustomer },

  { path: '/customerDetails/:id', name: 'Customer Details', element: CustomerDetails },
  { path: '/settings', name: 'Settings', element: Settings },

  { path: '/addNewCar', name: 'addNewCar', element: AddNewCar },
  { path: '/carList', name: 'carList', element: CarList },
  { path: '/editMake/:id', name: 'editmake', element: EditProduct },
  { path: '/editMakeDetails/:id', name: 'editMakeDetails', element: EditMakeDetails },


  { path: '/viewCar/:id', name: 'viewCar', element: ViewCar },
  { path: '/editCar/:id', name: 'editCar', element: EditCar },
  { path: '/vehicleType', name: 'vehicleList', element: VehicleTypeList },
  { path: '/addNewVehicleType', name: 'vehicleList', element: AddNewVehicleType },
  { path: '/editVehicle/:id', name: 'editVehicleType', element: EditVehicleType },
  { path: '/enquiryManagement', name: 'financeManagement', element: FinanceManagement },
  { path: '/viewFinance/:id', name: 'viewFinance', element: ViewFinance },
  { path: '/userList', name: 'userList', element: UserList },
  { path: '/userDetails/:id', name: 'userDetails', element: UserDetails },
  { path: '/addNewUser', name: 'addNewUser', element: AddNewUser },
  { path: '/viewDealer/:id', name: 'viewDealer', element: ViewDealer },
  { path: '/aboutUs', name: 'aboutUs', element: AboutUsForm },
  { path: '/policy', name: 'policy', element: PolicyAndServices },
  { path: '/contact-messages', name: 'contact-messages', element: ContactMessages },
  { path: '/BookingAppointment', name: 'BookingAppointment', element: AppointmentBooking },
  { path: '/popover', name: 'popover', element: popover },

]

export default routes
