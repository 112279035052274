import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const AddNewUser = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    contactNumbers: '',
    emailAddresses: '',
    username:"",
    address:'',
    city:'',
    state:'',
    zipcode:'',

  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (formData.password !== formData.confirmPassword) {
      MySwal.fire({
        icon: 'error',
        title: 'Passwords do not match!',
        text: 'Please make sure the passwords match.',
      })
      return
    }

    try {
      const response = await fetch(Constants.addNewUser, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          password: formData.password,
          contactNumbers: formData.contactNumbers,
          emailAddresses: formData.emailAddresses,
          username: formData.username,
          address:formData.address,
          city:formData.city,
          state: formData.state,
          zipcode:formData.zipcode,
        }),
      })

      if (response.ok) {
        MySwal.fire({
          icon: 'success',
          title: 'User Registered Successfully!',
        })

        navigate('/userList')
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Registration Failed!',
          text: 'An error occurred while registering the user.',
        })
      }
    } catch (error) {
      console.error('Error during registration:', error)
    }
  }

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">ADD NEW USER</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active text-info">Add New User</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-2">
                  <div className="card-body">
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <Form.Group className="col-lg-6 mb-3" controlId="firstName">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="lastName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="mobile">
                          <Form.Label>Mobile</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Mobile"
                            name="contactNumbers"
                            value={formData.contactNumbers}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            name="emailAddresses"
                            value={formData.emailAddresses}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="password">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="confirmPassword">
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password Again"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="username">
                          <Form.Label>Username</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="address">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                          
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="city">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter City"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                           
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="state">
                          <Form.Label>State</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter State"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                    
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="zipcode">
                          <Form.Label>Zip Code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Zip Code"
                            name="zipcode"
                            value={formData.zipcode}
                            onChange={handleChange}
                         
                          />
                        </Form.Group>

                        <div className="text-end">
                          <Button
                            variant="outline-info"
                            className="min-btn m-2"
                            as={Link}
                            to="/dashboard"
                          >
                            Cancel
                          </Button>
                          <Button variant="info" className="min-btn m-2" type="submit">
                            Save
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNewUser
