import React, { useState, useCallback, useEffect } from 'react'
import { FaEye } from 'react-icons/fa'
import Switch from 'react-switch'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import { Constants } from 'src/Constants'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
const MySwal = withReactContent(Swal)

const CustomerList = () => {
  const [customers, setCustomers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const location = useLocation()

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortColumn, setSortColumn] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(Constants.getAllDealers)
        if (response.ok) {
          const data = await response.json()
          setCustomers(data)
        } else {
          console.error('Failed to fetch customers')
        }
      } catch (error) {
        console.error('Error fetching customers:', error)
      }
    }

    fetchCustomers()
  }, [])

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
  }

  const sortedCustomers = customers.sort((a, b) => {
    const columnA = a[sortColumn]
    const columnB = b[sortColumn]

    if (columnA && columnB) {
      const valueA = typeof columnA === 'string' ? columnA : String(columnA)
      const valueB = typeof columnB === 'string' ? columnB : String(columnB)

      if (sortOrder === 'asc') {
        return valueA.localeCompare(valueB)
      } else {
        return valueB.localeCompare(valueA)
      }
    }

    return 0
  })

  const filteredCustomers = sortedCustomers.filter((customer) =>
    `${customer.firstName} ${customer.lastName} ${customer.email} ${customer.company} ${customer.status} ${customer.carCompany}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  )
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredCustomers.slice(indexOfFirstItem, indexOfLastItem)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)


  const handleStatusToggle = useCallback((id, currentStatus) => {
    fetch(`${Constants.updateDealerStatus}${id}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: currentStatus === 'active' ? 'inactive' : 'active' }),
    })
      .then((response) => {
        if (response.ok) {
          setCustomers((prevCustomers) =>
            prevCustomers.map((customer) =>
              customer._id === id
                ? { ...customer, status: currentStatus === 'active' ? 'inactive' : 'active' }
                : customer,
            ),
          )
          MySwal.fire('Updated!', 'Dealer status has been updated.', 'success')
        } else {
          throw new Error('Failed to update Dealer status.')
        }
      })
      .catch((error) => {
        console.error('Error updating Dealer status:', error)
        MySwal.fire('Error', 'Failed to update Dealer status.', 'error')
      })
  }, [])

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">DEALER LIST</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item text-info active">Dealer List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <h5 className="h6 text-uppercase mb-3">Dealer Account List </h5>
                  <Row>
                    <Col md={4} xs={12} className="mb-3 text-center">
                      <h3 className="fw-bolder text-info mb-0">{customers.length}</h3>
                      <p className="text-secondary">Total Users</p>
                    </Col>
                  </Row>

                  <div className="dataTables_length mb-2">
                    <div className="search-box">
                      <input
                        type="text"
                        id="search"
                        placeholder="Search "
                        name="search"
                        className="form-control "
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="table-responsive">
                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th onClick={() => handleSort('firstName')}>
                            First Name
                            {sortColumn === 'firstName' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('lastName')}>
                            Last Name
                            {sortColumn === 'lastName' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('email')}>
                            Email
                            {sortColumn === 'email' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>

                          {/* <th onClick={() => handleSort('city')}>
                            City
                            {sortColumn === 'city' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th> */}
                          <th onClick={() => handleSort('country')}>
                            Company
                            {sortColumn === 'country' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                         
                          <th onClick={() => handleSort('mobile')}>
                            Makes
                            {sortColumn === 'mobile' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentItems?.map((customer, index) => (
                          <tr key={customer?._id}>
                            <td>{customer?.firstName}</td>
                            <td>{customer?.lastName}</td>

                            <td>{customer?.email}</td>

                            <td>{customer?.company}</td>
                            <td>{customer?.carCompany}</td>
                            <td>
                              
                              <Switch
                                    onChange={() => handleStatusToggle(customer._id, customer.status)}
                                    checked={customer.status === 'active'}
                                    onColor="#86d38a"
                                    offColor="#e44d4d"
                                    height={20}
                                    width={40}
                                  />
                            </td>
                           

                            <td>
                              <div className="icon-container">
                                <Link className="btn btn-sm btn-info text-white" to={`/viewDealer/${customer._id}`}>
                                  <FaEye title="View Customer" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Container>
                    <div className="col-sm-12 col-md-5">
                      <div className="dataTables_length">
                        Showing {indexOfFirstItem + 1} to{' '}
                        {Math.min(indexOfLastItem, customers.length)} of {customers.length} entries
                      </div>
                    </div>
                    <div>
                      {Array.from({
                        length: Math.ceil(customers.length / itemsPerPage),
                      }).map((_, index) => (
                        <button
                          key={index}
                          type="button"
                          className={`btn ${
                            currentPage === index + 1
                              ? 'btn-info rounded-circle text-white m-1'
                              : 'btn-outline-info m-1 btn-sm'
                          }`}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </button>
                      ))}
                    </div>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default CustomerList
