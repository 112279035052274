import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const AddNewCustomer = () => {
  const navigate = useNavigate();
  const [carMakes, setCarMakes] = useState([])
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    country: '',
    address: '',
    city: '',
    zip: '',
    company: '', 
    companyDetails: '',
    website: '',
    businessTime: '', 
    mobile: '',
    email: '',
    password: '',
    carCompany: '',
    status: 'active',
  });

  useEffect(() => {
    const fetchMakes = async () => {
      try {
        //  car makes
        const makesResponse = await fetch(Constants.getAllMakes)
        if (!makesResponse.ok) {
          throw new Error(`HTTP error! Status: ${makesResponse.status}`)
        }
        const makesData = await makesResponse.json()

        const activeMakes = makesData.filter((make) => make.status === 'active')
        const makeNames = activeMakes.map((make) => make.name)
        setCarMakes(makeNames)
      } catch (error) {
        console.error('Error fetching data:', error.message)
      }
    }

    fetchMakes()
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSave = async () => {
    try {
      const response = await fetch(Constants.addNewDealer, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      MySwal.fire('Saved!', 'Dealer information has been saved.', 'success')
      navigate('/customerList'); 
    } catch (error) {
      console.error('Error saving data:', error.message)
      MySwal.fire('Error', 'Failed to save dealer information.', 'error')
    }
  }

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">ADD NEW DEALER</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active text-info">Add New Dealer</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-2">
                  <div className="card-body">
                    <h5 className="h6 text-uppercase mb-3">Dealer Registration </h5>
                    <Form>
                      <div className="row">
                        <Form.Group className="col-lg-6 mb-3" controlId="firstName">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="lastName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6  mb-3" controlId="address">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={1}
                            placeholder="Enter Address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="city">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter City"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="zip">
                          <Form.Label>Zip</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Zip"
                            name="zip"
                            value={formData.zip}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="country">
                          <Form.Label>Country</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Country"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="company">
                          <Form.Label>Company Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Company Name"
                            name="company"
                            value={formData.company}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6  mb-3" controlId="website">
                          <Form.Label>Website</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Website"
                            name="website"
                            value={formData.website}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="companyDetails">
                          <Form.Label>Company Details</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter Company Details"
                            name="companyDetails"
                            value={formData.companyDetails}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="businessTime">
                          <Form.Label>How long have you been in business?</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Business Duration"
                            name="businessTime"
                            value={formData.businessTime}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="mobile">
                          <Form.Label>Mobile</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Mobile"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="carCompany">
                          <Form.Label>Car Company</Form.Label>
                          <Form.Control
                            as="select"
                            name="carCompany"
                            value={formData.carCompany}
                            onChange={handleChange}
                          >
                            <option value="">Select Car Company</option>
                            {carMakes.map((makeOption, index) => (
                              <option key={index} value={makeOption}>
                                {makeOption}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <div className="text-end">
                          <Button
                            variant="outline-info"
                            className="min-btn m-2"
                            onClick={() => MySwal.fire('Cancelled', 'Operation cancelled', 'info')}
                          >
                            Cancel
                          </Button>
                          <Button variant="info" className="min-btn m-2" onClick={handleSave}>
                            Save
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNewCustomer
