import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import Accordion from 'react-bootstrap/Accordion'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

const popover = () => {
  const [formData, setFormData] = useState({
    vehicleTypeInfo: '',

    salePriceInfo: '',
    estimatedSalePriceInfo: '',
    freightInfo: '',
    rebateInfo: '',
    discountInfo: '',

    tradeInInfo: '',
    lienInfo: '',

    // extraInfo: '',
    totalExtraInfo: '',
    floorMatsInfo: '',
    tireWheelInfo: '',
    truckBedLinerInfo: '',
    trailerPackageInfo: '',
    windowTintingInfo: '',
    otherExtraInfo: '',
    // otherExtraInfo: '',

    protectionPackageInfo: '',
    ceramicCoatingInfo: '',
    rustProofingInfo: '',
    paintProtectionInfo: '',
    fabricProtectionInfo: '',
    // windowEtchingInfo: '',
    securitySystemInfo: '',
    otherProtectionInfo: '',

    warrantyInfo: '',
    // comprehensiveWarrantyInfo: '',
    extendedWarrantyInfo: '',
    powerTrainWarrantyInfo: '',

    insuranceInfo: '',
    lifeInsuranceInfo: '',
    replacementInsuranceInfo: '',

    otherTaxesAndFeesInfo: '',
    adminFeeInfo: '',
    airtaxInfo: '',
    tireTaxInfo: '',
    gasolineInfo: '',
    omvicInfo: '',
    lienRegistrationInfo: '',
    licenseFeeInfo: '',
    otherTaxInfo: '',

    // payableOnDeliveryInfo: '',
    // frequencyInfo: '',
    // downPaymentInfo: '',
    termsInfo: '',
    // interestRateInfo: '',
    taxRateInfo: '',
    // gstInfo: '',
    noteInfo: '',
    termsAndConditionInfo: '',
    ageConfirmationInfo: '',
  })
  const [financeData, setFinanceData] = useState({
    creditPreApprovalInfo: '',
    vehicleTypeInfo: '',

    salePriceInfo: '',
    estimatedSalePriceInfo: '',
    freightInfo: '',
    rebateInfo: '',
    discountInfo: '',

    tradeInInfo: '',
    lienInfo: '',

    totalExtraInfo: '',
    floorMatsInfo: '',
    tireWheelInfo: '',
    truckBedLinerInfo: '',
    trailerPackageInfo: '',
    windowTintingInfo: '',
    otherExtraInfo: '',

    protectionPackageInfo: '',
    ceramicCoatingInfo: '',
    rustProofingInfo: '',
    paintProtectionInfo: '',
    fabricProtectionInfo: '',
    securitySystemInfo: '',
    otherProtectionInfo: '',

    warrantyInfo: '',
    extendedWarrantyInfo: '',
    powerTrainWarrantyInfo: '',

    insuranceInfo: '',
    lifeInsuranceInfo: '',
    replacementInsuranceInfo: '',

    otherTaxesAndFeesInfo: '',
    adminFeeInfo: '',
    airtaxInfo: '',
    tireTaxInfo: '',
    gasolineInfo: '',
    omvicInfo: '',
    lienRegistrationInfo: '',
    licenseFeeInfo: '',
    otherTaxInfo: '',

    frequencyInfo: '',
    downPaymentInfo: '',
    termsInfo: '',
    interestRateInfo: '',
    taxRateInfo: '',
    gstInfo: '',
    noteInfo: '',
    termsAndConditionInfo: '',
    ageConfirmationInfo: '',
  })
  const [leaseData, setLeaseData] = useState({
    creditPreApprovalInfo: '',

    vehicleTypeInfo: '',

    capitalizedCostInfo: '',
    estimatedSalePriceInfo: '',
    freightInfo: '',

    residualInfo: '',
    expectedKMInfo: '',

    capitalCostReductionInfo: '',
    rebateInfo: '',
    discountInfo: '',
    downPaymentInfo: '',

    tradeInInfo: '',
    lienInfo: '',

    totalExtraInfo: '',
    floorMatsInfo: '',
    tireWheelInfo: '',
    truckBedLinerInfo: '',
    trailerPackageInfo: '',
    windowTintingInfo: '',
    otherExtraInfo: '',

    protectionPackageInfo: '',
    ceramicCoatingInfo: '',
    rustProofingInfo: '',
    paintProtectionInfo: '',
    fabricProtectionInfo: '',
    securitySystemInfo: '',
    otherProtectionInfo: '',

    estimatedMaintenanceInfo: '',
    maintenancePackageInfo: '',
    leaseProtectionInfo: '',

    insuranceInfo: '',
    lifeInsuranceInfo: '',
    replacementInsuranceInfo: '',

    otherTaxesAndFeesInfo: '',
    adminFeeInfo: '',
    airtaxInfo: '',
    tireTaxInfo: '',
    gasolineInfo: '',
    omvicInfo: '',
    lienRegistrationInfo: '',
    licenseFeeInfo: '',
    vehicleDropOffFeeInfo: '',
    otherTaxInfo: '',
    // -----------------------------------

    securityDepositInfo: '',

    termsInfo: '',
    interestRateInfo: '',
    taxRateInfo: '',
    gstInfo: '',
    noteInfo: '',
    termsAndConditionInfo: '',
    ageConfirmationInfo: '',
  })

  useEffect(() => {
    fetchData()
    fetchFinanceData()
    fetchLeaseData()
  }, [])

  const fetchData = async () => {
    try {
      const response = await fetch('https://api.icarbuyer.co/api/popoverInfo')
      const data = await response.json()
      setFormData(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  const fetchFinanceData = async () => {
    try {
      const response = await fetch('https://api.icarbuyer.co/api/popoverInfoFinance')
      const data = await response.json()
      setFinanceData(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  const fetchLeaseData = async () => {
    try {
      const response = await fetch('https://api.icarbuyer.co/api/popoverInfoLease')
      const data = await response.json()
      setLeaseData(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleChange = (e) => {
    const { id, value } = e.target
    setFormData({ ...formData, [id]: value })
  }

  const handleFinanceChange = (e) => {
    const { id, value } = e.target
    setFinanceData({ ...financeData, [id]: value })
  }
  const handleLeaseChange = (e) => {
    const { id, value } = e.target
    setLeaseData({ ...leaseData, [id]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch('https://api.icarbuyer.co/api/popoverInfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      setFormData(data)
      Swal.fire('Success', 'Data uploaded/updated successfully!', 'success')
    } catch (error) {
      console.error('Error uploading data:', error)
      Swal.fire('Error', 'An error occurred while uploading data.', 'error')
    }
  }
  const handleFinanceSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch('https://api.icarbuyer.co/api/popoverInfoFinance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(financeData),
      })
      const data = await response.json()
      setFinanceData(data)
      Swal.fire('Success', 'Data uploaded/updated successfully!', 'success')
    } catch (error) {
      console.error('Error uploading data:', error)
      Swal.fire('Error', 'An error occurred while uploading data.', 'error')
    }
  }
  const handleLeaseSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch('https://api.icarbuyer.co/api/popoverInfoLease', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leaseData),
      })
      const data = await response.json()
      setLeaseData(data)
      Swal.fire('Success', 'Data uploaded/updated successfully!', 'success')
    } catch (error) {
      console.error('Error uploading data:', error)
      Swal.fire('Error', 'An error occurred while uploading data.', 'error')
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">POP OVER INFORMATION SECTION</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Pop Over Informations</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="popoverx">
            <Tabs
              defaultActiveKey="cash"
              id="uncontrolled-tab-example"
              className="mb-3"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom: '2px solid #007bff',
                marginBottom: '20px !important',
                paddingBottom: '20px',
                marginTop: '20px',
              }}
            >
              <Tab
                eventKey="cash"
                title="Cash"
                tabClassName="custom-tab"
                style={{
                  padding: '10px 20px',
                  margin: '0 10px',
                  backgroundColor: '#e3f2fd',
                  borderRadius: '8px',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#0d47a1',
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <Col md={16}>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Vehicle Selection Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="vehicleTypeInfo">
                            <Form.Label className="fw-bold">Vehicle Type Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.vehicleTypeInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Vehicle Sale Price Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="salePriceInfo">
                            <Form.Label className="fw-bold">Sale Price Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.salePriceInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="freightInfo">
                            <Form.Label className="fw-bold">Freight Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.freightInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="rebateInfo">
                            <Form.Label className="fw-bold">Rebate Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.rebateInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="discountInfo">
                            <Form.Label className="fw-bold">Discount Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.discountInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Estimated Optional Extras Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="totalExtraInfo">
                            <Form.Label className="fw-bold">Total Extras Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.totalExtraInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="floorMatsInfo">
                            <Form.Label className="fw-bold">Floor Mats Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.floorMatsInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="tireWheelInfo">
                            <Form.Label className="fw-bold">Tire & Wheel Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.tireWheelInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="truckBedLinerInfo">
                            <Form.Label className="fw-bold">Truck Bed Liner Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.truckBedLinerInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="trailerPackageInfo">
                            <Form.Label className="fw-bold">Trailer Package Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.trailerPackageInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="windowTintingInfo">
                            <Form.Label className="fw-bold">Window Tinting Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.windowTintingInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="otherExtraInfo">
                            <Form.Label className="fw-bold">Other Extra Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.otherExtraInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          Estimated Optional Protection Packages Section
                        </Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="protectionPackageInfo">
                            <Form.Label className="fw-bold">
                              Estimated Protection Package Info
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.protectionPackageInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="ceramicCoatingInfo">
                            <Form.Label className="fw-bold">Ceramic Coating Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.ceramicCoatingInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="rustProofingInfo">
                            <Form.Label className="fw-bold">Rust Proofing Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.rustProofingInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="paintProtectionInfo">
                            <Form.Label className="fw-bold">Paint Protection Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.paintProtectionInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="fabricProtectionInfo">
                            <Form.Label className="fw-bold">Fabric Protection Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.fabricProtectionInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="securitySystemInfo">
                            <Form.Label className="fw-bold">Security System Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.securitySystemInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="otherProtectionInfo">
                            <Form.Label className="fw-bold">
                              Other Protection Packages Info
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.otherProtectionInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>Warranty Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="warrantyInfo">
                            <Form.Label className="fw-bold">Warranty Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.warrantyInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="extendedWarrantyInfo">
                            <Form.Label className="fw-bold">Extended Warranty Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.extendedWarrantyInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="powerTrainWarrantyInfo">
                            <Form.Label className="fw-bold">Power Train Warranty Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.powerTrainWarrantyInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                        <Accordion.Header>Insurance Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="insuranceInfo">
                            <Form.Label className="fw-bold">Insurance Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.insuranceInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="lifeInsuranceInfo">
                            <Form.Label className="fw-bold">
                              Life & Disability Insurance Info
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.lifeInsuranceInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="replacementInsuranceInfo">
                            <Form.Label className="fw-bold">Replacement Insurance Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.replacementInsuranceInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="7">
                        <Accordion.Header>Other Taxes & Fees Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="otherTaxesAndFeesInfo">
                            <Form.Label className="fw-bold">Other Taxes & Fees Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.otherTaxesAndFeesInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="adminFeeInfo">
                            <Form.Label className="fw-bold">Admin Fee Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.adminFeeInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="airtaxInfo">
                            <Form.Label className="fw-bold">Airtax Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.airtaxInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="tireTaxInfo">
                            <Form.Label className="fw-bold">Tire Tax Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.tireTaxInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="gasolineInfo">
                            <Form.Label className="fw-bold">Gasoline Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.gasolineInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="omvicInfo">
                            <Form.Label className="fw-bold">OMVIC Fees Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.omvicInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="lienRegistrationInfo">
                            <Form.Label className="fw-bold">Lien Registration Fees Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.lienRegistrationInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="licenseFeeInfo">
                            <Form.Label className="fw-bold">License Fee Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.licenseFeeInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="otherTaxInfo">
                            <Form.Label className="fw-bold">Other Tax Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.otherTaxInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Trade-In section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="tradeInInfo">
                            <Form.Label className="fw-bold">Trade-In Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.tradeInInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="lienInfo">
                            <Form.Label className="fw-bold">Lien Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.lienInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="8">
                        <Accordion.Header> Sales Tax Info</Accordion.Header>
                        <Accordion.Body>
                          {/* <Form.Group className="mb-2" controlId="frequencyInfo">
                            <Form.Label className="fw-bold">Frequency Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData.frequencyInfo}
                              onChange={handleChange}
                            />
                          </Form.Group> */}
                          {/* <Form.Group className="mb-2" controlId="termsInfo">
                            <Form.Label className="fw-bold">Terms in Year Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData.termsInfo}
                              onChange={handleChange}
                            />
                          </Form.Group> */}
                          {/* <Form.Group className="mb-2" controlId="interestRateInfo">
                            <Form.Label className="fw-bold">Interest Rate Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData.interestRateInfo}
                              onChange={handleChange}
                            /> */}
                          {/* </Form.Group> */}
                          <Form.Group className="mb-2" controlId="taxRateInfo">
                            <Form.Label className="fw-bold">Tax Rate Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData?.taxRateInfo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          {/* <Form.Group className="mb-2" controlId="gstInfo">
                            <Form.Label className="fw-bold">
                              Tax Type Info (GST, HST or PST)
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={formData.gstInfo}
                              onChange={handleChange}
                            />
                          </Form.Group> */}
                        </Accordion.Body>
                      </Accordion.Item>

                      <Button type="submit" className="btn btn-info my-1">
                        Save
                      </Button>
                    </Accordion>
                  </Col>
                </Form>
              </Tab>
              <Tab
                eventKey="finance"
                title="Finance"
                tabClassName="custom-tab"
                style={{
                  padding: '10px 20px',
                  margin: '0 10px',
                  backgroundColor: '#e3f2fd',
                  borderRadius: '8px',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#0d47a1',
                }}
              >
                <Form onSubmit={handleFinanceSubmit}>
                  <Col md={16}>
                    <Accordion>
                      <Accordion.Item eventKey="9">
                        <Accordion.Header>Credit Pre-Approval Info</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="creditPreApprovalInfo">
                            <Form.Label className="fw-bold">Credit Pre-Approval Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.creditPreApprovalInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Vehicle Selection Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="vehicleTypeInfo">
                            <Form.Label className="fw-bold">Vehicle Type Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.vehicleTypeInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Vehicle Sale Price Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="salePriceInfo">
                            <Form.Label className="fw-bold">Sale Price Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.salePriceInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="freightInfo">
                            <Form.Label className="fw-bold">Freight Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.freightInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="rebateInfo">
                            <Form.Label className="fw-bold">Rebate Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.rebateInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="discountInfo">
                            <Form.Label className="fw-bold">Discount Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.discountInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Estimated Optional Extras Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="totalExtraInfo">
                            <Form.Label className="fw-bold">Total Extras Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.totalExtraInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="floorMatsInfo">
                            <Form.Label className="fw-bold">Floor Mats Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.floorMatsInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="tireWheelInfo">
                            <Form.Label className="fw-bold">Tire & Wheel Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.tireWheelInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="truckBedLinerInfo">
                            <Form.Label className="fw-bold">Truck Bed Liner Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.truckBedLinerInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="trailerPackageInfo">
                            <Form.Label className="fw-bold">Trailer Package Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.trailerPackageInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="windowTintingInfo">
                            <Form.Label className="fw-bold">Window Tinting Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.windowTintingInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="otherExtraInfo">
                            <Form.Label className="fw-bold">Other Extra Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.otherExtraInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          Estimated Optional Protection Packages Section
                        </Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="protectionPackageInfo">
                            <Form.Label className="fw-bold">
                              Estimated Protection Package Info
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.protectionPackageInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="ceramicCoatingInfo">
                            <Form.Label className="fw-bold">Ceramic Coating Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.ceramicCoatingInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="rustProofingInfo">
                            <Form.Label className="fw-bold">Rust Proofing Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.rustProofingInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="paintProtectionInfo">
                            <Form.Label className="fw-bold">Paint Protection Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.paintProtectionInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="fabricProtectionInfo">
                            <Form.Label className="fw-bold">Fabric Protection Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.fabricProtectionInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="securitySystemInfo">
                            <Form.Label className="fw-bold">Security System Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.securitySystemInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="otherProtectionInfo">
                            <Form.Label className="fw-bold">
                              Other Protection Packages Info
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.otherProtectionInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>Warranty Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="warrantyInfo">
                            <Form.Label className="fw-bold">Warranty Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.warrantyInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="extendedWarrantyInfo">
                            <Form.Label className="fw-bold">Extended Warranty Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.extendedWarrantyInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="powerTrainWarrantyInfo">
                            <Form.Label className="fw-bold">Power Train Warranty Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.powerTrainWarrantyInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                        <Accordion.Header>Insurance Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="insuranceInfo">
                            <Form.Label className="fw-bold">Insurance Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.insuranceInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="lifeInsuranceInfo">
                            <Form.Label className="fw-bold">
                              Life & Disability Insurance Info
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.lifeInsuranceInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="replacementInsuranceInfo">
                            <Form.Label className="fw-bold">Replacement Insurance Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.replacementInsuranceInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="7">
                        <Accordion.Header>Other Taxes & Fees Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="otherTaxesAndFeesInfo">
                            <Form.Label className="fw-bold">Other Taxes & Fees Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.otherTaxesAndFeesInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="adminFeeInfo">
                            <Form.Label className="fw-bold">Admin Fee Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.adminFeeInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="airtaxInfo">
                            <Form.Label className="fw-bold">Airtax Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.airtaxInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="tireTaxInfo">
                            <Form.Label className="fw-bold">Tire Tax Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.tireTaxInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="gasolineInfo">
                            <Form.Label className="fw-bold">Gasoline Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.gasolineInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="omvicInfo">
                            <Form.Label className="fw-bold">OMVIC Fees Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.omvicInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="lienRegistrationInfo">
                            <Form.Label className="fw-bold">Lien Registration Fees Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.lienRegistrationInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="licenseFeeInfo">
                            <Form.Label className="fw-bold">License Fee Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.licenseFeeInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="otherTaxInfo">
                            <Form.Label className="fw-bold">Other Tax Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.otherTaxInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Trade-In section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="tradeInInfo">
                            <Form.Label className="fw-bold">Trade-In Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.tradeInInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="lienInfo">
                            <Form.Label className="fw-bold">Lien Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.lienInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="10">
                        <Accordion.Header>Downpayment Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="downPaymentInfo">
                            <Form.Label className="fw-bold">Downpayment Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.downPaymentInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="8">
                        <Accordion.Header>Terms, Rate & Sales Tax Info</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="frequencyInfo">
                            <Form.Label className="fw-bold">Frequency Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.frequencyInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="termsInfo">
                            <Form.Label className="fw-bold">Terms in Year Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.termsInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="interestRateInfo">
                            <Form.Label className="fw-bold">Interest Rate Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.interestRateInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="taxRateInfo">
                            <Form.Label className="fw-bold">Tax Rate Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.taxRateInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="gstInfo">
                            <Form.Label className="fw-bold">
                              Tax Type Info (GST, HST or PST)
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={financeData?.gstInfo}
                              onChange={handleFinanceChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Button type="submit" className="btn btn-info my-1">
                        Save
                      </Button>
                    </Accordion>
                  </Col>
                </Form>
              </Tab>
              <Tab
                eventKey="lease"
                title="Lease"
                tabClassName="custom-tab"
                style={{
                  padding: '10px 20px',
                  margin: '0 10px',
                  backgroundColor: '#e3f2fd',
                  borderRadius: '8px',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#0d47a1',
                }}
              >
                <Form onSubmit={handleLeaseSubmit}>
                  <Col md={16}>
                    <Accordion>
                      <Accordion.Item eventKey="9">
                        <Accordion.Header>Credit Pre-Approval Info</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="creditPreApprovalInfo">
                            <Form.Label className="fw-bold">Credit Pre-Approval Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.creditPreApprovalInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Vehicle Selection Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="vehicleTypeInfo">
                            <Form.Label className="fw-bold">Vehicle Type Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.vehicleTypeInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Capitalized Cost (MSRP) Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="capitalizedCostInfo">
                            <Form.Label className="fw-bold">Capitalized Cost Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.capitalizedCostInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="estimatedSalePriceInfo">
                            <Form.Label className="fw-bold">Estimated Sale Price Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.estimatedSalePriceInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="freightInfo">
                            <Form.Label className="fw-bold">Freight Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.freightInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="11">
                        <Accordion.Header>
                          Estimated Residual Value (Buyout) Section
                        </Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="residualInfo">
                            <Form.Label className="fw-bold">Residual Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.residualInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="expectedKMInfo">
                            <Form.Label className="fw-bold">Expected KM Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.expectedKMInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="12">
                        <Accordion.Header>Capital Cost Reduction Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="capitalCostReductionInfo">
                            <Form.Label className="fw-bold">Capital Cost Reduction Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.capitalCostReductionInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="rebateInfo">
                            <Form.Label className="fw-bold">Rebate Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.rebateInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="discountInfo">
                            <Form.Label className="fw-bold">Discount Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.discountInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="downPaymentInfo">
                            <Form.Label className="fw-bold">Downpayment Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.downPaymentInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Estimated Optional Extras Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="totalExtraInfo">
                            <Form.Label className="fw-bold">Total Extras Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.totalExtraInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="floorMatsInfo">
                            <Form.Label className="fw-bold">Floor Mats Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.floorMatsInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="tireWheelInfo">
                            <Form.Label className="fw-bold">Tire & Wheel Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.tireWheelInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="truckBedLinerInfo">
                            <Form.Label className="fw-bold">Truck Bed Liner Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.truckBedLinerInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="trailerPackageInfo">
                            <Form.Label className="fw-bold">Trailer Package Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.trailerPackageInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="windowTintingInfo">
                            <Form.Label className="fw-bold">Window Tinting Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.windowTintingInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="otherExtraInfo">
                            <Form.Label className="fw-bold">Other Extra Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.otherExtraInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          Estimated Optional Protection Packages Section
                        </Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="protectionPackageInfo">
                            <Form.Label className="fw-bold">
                              Estimated Protection Package Info
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.protectionPackageInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="ceramicCoatingInfo">
                            <Form.Label className="fw-bold">Ceramic Coating Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.ceramicCoatingInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="rustProofingInfo">
                            <Form.Label className="fw-bold">Rust Proofing Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.rustProofingInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="paintProtectionInfo">
                            <Form.Label className="fw-bold">Paint Protection Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.paintProtectionInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="fabricProtectionInfo">
                            <Form.Label className="fw-bold">Fabric Protection Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.fabricProtectionInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="securitySystemInfo">
                            <Form.Label className="fw-bold">Security System Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.securitySystemInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="otherProtectionInfo">
                            <Form.Label className="fw-bold">
                              Other Protection Packages Info
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.otherProtectionInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>Maintenance & Lease Protection Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="estimatedMaintenanceInfo">
                            <Form.Label className="fw-bold">Estimated Maintenance Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.estimatedMaintenanceInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="maintenancePackageInfo">
                            <Form.Label className="fw-bold">Maintenance Package Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.maintenancePackageInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="leaseProtectionInfo">
                            <Form.Label className="fw-bold">Lease Protection Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.leaseProtectionInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                        <Accordion.Header>Insurance Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="insuranceInfo">
                            <Form.Label className="fw-bold">Insurance Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.insuranceInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="lifeInsuranceInfo">
                            <Form.Label className="fw-bold">
                              Life & Disability Insurance Info
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.lifeInsuranceInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="replacementInsuranceInfo">
                            <Form.Label className="fw-bold">Replacement Insurance Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.replacementInsuranceInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="7">
                        <Accordion.Header>Other Taxes & Fees Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="otherTaxesAndFeesInfo">
                            <Form.Label className="fw-bold">Other Taxes & Fees Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.otherTaxesAndFeesInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="adminFeeInfo">
                            <Form.Label className="fw-bold">Admin Fee Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.adminFeeInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="airtaxInfo">
                            <Form.Label className="fw-bold">Airtax Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.airtaxInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="tireTaxInfo">
                            <Form.Label className="fw-bold">Tire Tax Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.tireTaxInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="gasolineInfo">
                            <Form.Label className="fw-bold">Gasoline Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.gasolineInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="omvicInfo">
                            <Form.Label className="fw-bold">OMVIC Fees Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.omvicInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="lienRegistrationInfo">
                            <Form.Label className="fw-bold">Lien Registration Fees Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.lienRegistrationInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="licenseFeeInfo">
                            <Form.Label className="fw-bold">License Fee Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.licenseFeeInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="vehicleDropOffFeeInfo">
                            <Form.Label className="fw-bold">Vehicle Drop Off Fee Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.vehicleDropOffFeeInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="otherTaxInfo">
                            <Form.Label className="fw-bold">Other Tax Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.otherTaxInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Trade-In section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="tradeInInfo">
                            <Form.Label className="fw-bold">Trade-In Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.tradeInInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-2" controlId="lienInfo">
                            <Form.Label className="fw-bold">Lien Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.lienInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="10">
                        <Accordion.Header>Security Deposit Section</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="securityDepositInfo">
                            <Form.Label className="fw-bold">Security Deposit Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.securityDepositInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="8">
                        <Accordion.Header>Terms, Rate & Sales Tax Info</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-2" controlId="frequencyInfo">
                            <Form.Label className="fw-bold">Frequency Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.frequencyInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="termsInfo">
                            <Form.Label className="fw-bold">Terms in Year Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.termsInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="interestRateInfo">
                            <Form.Label className="fw-bold">Interest Rate Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.interestRateInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="taxRateInfo">
                            <Form.Label className="fw-bold">Tax Rate Info</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.taxRateInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2" controlId="gstInfo">
                            <Form.Label className="fw-bold">
                              Tax Type Info (GST, HST or PST)
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={leaseData?.gstInfo}
                              onChange={handleLeaseChange}
                            />
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Button type="submit" className="btn btn-info my-1">
                        Save
                      </Button>
                    </Accordion>
                  </Col>
                </Form>
              </Tab>
            </Tabs>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default popover
