import React from 'react'
import { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
import Stepper from 'react-stepper-horizontal'
import { Constants } from 'src/Constants'

const EditCar = () => {
  const location = useLocation()

  const carId = location.pathname.split('/').pop()
  const [response, setResponse] = useState(null)
  const [carDetails, setCarDetails] = useState([])
  const [carMakes, setCarMakes] = useState([])
  const [vehicleTypes, setVehicleTypes] = useState([])  //vehicle type
  const [step, setStep] = useState(1)
  const [mileage, setMileage] = useState('')
  const [year, setYear] = useState('')
  const [make, setMake] = useState('')
  const [vehicleType, setVehicleType] = useState('')//vehicle type
  const [model, setModel] = useState('')
  const [trim, setTrim] = useState('')
  const [basePrice, setBasePrice] = useState('')

  const [usedCondition, setUsedCondition] = useState('')
  // const [selectedMake, setSelectedMake] = useState('');
  const [rustProofingChecked, setRustProofingChecked] = useState(false)
  const [rustProofingDetails, setRustProofingDetails] = useState('')
  const [paintProtectionChecked, setPaintProtectionChecked] = useState(false)
  const [paintProtectionDetails, setPaintProtectionDetails] = useState('')
  const [fabricProtectionChecked, setFabricProtectionChecked] = useState(false)
  const [fabricProtectionDetails, setfabricProtectionDetails] = useState('')
  const [windowItchingChecked, setWindowItchingChecked] = useState(false)
  const [windowTintingDetails, setWindowTintingDetails] = useState('')
  const [otherProtectionChecked, setOtherProtectionChecked] = useState(false)
  const [otherProtectionPackagesdetails, setOtherProtectionPackagesdetails] = useState('')

  const [comprehensiveWarrantyChecked, setComprehensiveWarrantyChecked] = useState(false)
  const [comprehensiveWarrantyDetails, setComprehensiveWarrantyDetails] = useState('')

  const [powerTrainWarrantyChecked, setPowerTrainWarrantyChecked] = useState(false)
  const [powerTrainWarrantyDetails, setPowerTrainWarrantyDetails] = useState('')

  const [totalInsuranceChecked, setTotalInsuranceChecked] = useState(false)
  const [totalInsuranceDetails, setTotalInsuranceDetails] = useState('')

  const [estimatedTotalTaxesAndFeesChecked, setEstimatedTotalTaxesAndFeesChecked] = useState(false)
  const [estimatedTotalTaxesAndFeesDetails, setEstimatedTotalTaxesAndFeesDetails] = useState('')

  const [adminFeeChecked, setAdminFeeChecked] = useState(false)
  const [adminFeeDetails, setAdminFeeDetails] = useState('')

  const [airTaxChecked, setAirTaxChecked] = useState(false)
  const [airTaxdetails, setAirTaxdetails] = useState('')

  const [gasolineChecked, setGasolineChecked] = useState(false)
  const [gasolineDetails, setGasolineDetails] = useState('')

  const [omvicFeeChecked, setOmvicFeeChecked] = useState(false)
  const [omvicFeedetails, setOmvicFeedetails] = useState('')

  const [lienRegistrationFeeChecked, setLienRegistrationFeeChecked] = useState(false)
  const [lienRegistrationFeeDetails, setLienRegistrationFeeDetails] = useState('')

  const [licenseFeeChecked, setLicenseFeeChecked] = useState(false)
  const [licenseFeeDetails, setLicenseFeeDetails] = useState('')

  const [otherChecked, setOtherChecked] = useState(false)
  const [otherDetails, setOtherDetails] = useState('')

  const [payableOnDeliveryChecked, setPayableOnDeliveryChecked] = useState(false)
  const [payableOnDeliveryDetails, setPayableOnDeliveryDetails] = useState('')

  const [taxChecked, setTaxChecked] = useState({ gstChecked: false, hstChecked: false })
  const [taxRatePercentage, setTaxRatePercentage] = useState()
  const [note, setNote] = useState('')

  const [tireAndWheelChecked, setTireAndWheelChecked] = useState(false)
  const [tireAndWheelDetails, setTireAndWheelDetails] = useState('')

  const [sideStepsChecked, setSideStepsChecked] = useState(false)
  const [sideStepsdetails, setSideStepsdetails] = useState('')

  const [roofRacksChecked, setRoofRacksChecked] = useState(false)
  const [roofRacksDetails, setRoofRacksDetails] = useState('')

  const [trailerPackagesChecked, setTrailerPackagesChecked] = useState(false)
  const [trailerPackagesdetails, settrailerPackagesdetails] = useState('')

  const [sprayLinerChecked, setSprayLinerChecked] = useState(false)
  const [sprayLinerdetails, setSprayLinerdetails] = useState('')

  const handleCheckboxChange = (type) => {
    setTaxChecked((prevTaxChecked) => ({
      gstChecked: type === 'gstChecked' ? true : false,
      hstChecked: type === 'hstChecked' ? true : false,
    }))
  }
  const handleUsedCondition = (e) => {
    setUsedCondition(e.target.value)
  }

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1)
  }

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1)
  }
  useEffect(() => {
    const fetchMakesAndVehicleTypes = async () => {
      try {
        //  car makes
        const makesResponse = await fetch(Constants.getAllMakes);
        if (!makesResponse.ok) {
          throw new Error(`HTTP error! Status: ${makesResponse.status}`);
        }
        const makesData = await makesResponse.json();
  
        const activeMakes = makesData.filter((make) => make.status === 'active');
        const makeNames = activeMakes.map((make) => make.name);
        setCarMakes(makeNames);
  
        //  vehicle types
        // const vehicleTypesResponse = await fetch(Constants.getAllVehicleTypes);
        // if (!vehicleTypesResponse.ok) {
        //   throw new Error(`HTTP error! Status: ${vehicleTypesResponse.status}`);
        // }
        // const vehicleTypesData = await vehicleTypesResponse.json();
  
        // const activeVehicleTypes = vehicleTypesData.filter((type) => type.status === 'active');
        // const vehicleTypeNames = activeVehicleTypes.map((type) => type.name);
        // setVehicleTypes(vehicleTypeNames);
  
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };
  
    fetchMakesAndVehicleTypes();
  }, []);
  useEffect(() => {
    const fetchVehicleTypes = async () => {
      if (make) {
        try {
          const response = await fetch(`${Constants.getVehicleTypesByMakeName}${make}`);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
  
      
          if (Array.isArray(data) && data.length > 0 && data[0].vehicleTypes) {
           
            const activeVehicleTypes = data[0].vehicleTypes.filter(type => type.status === 'active');
            const vehicleTypeNames = activeVehicleTypes.map(type => type.name);
            setVehicleTypes(vehicleTypeNames);
          } else {
            console.error('Invalid data format received from API');
          }
        } catch (error) {
          console.error('Error fetching vehicle types:', error);
        }
      } else {
        // If no make selected, clear vehicle types
        setVehicleTypes([]);
      }
    };
  
    fetchVehicleTypes();
  }, [make]);

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        if (!carId) {
          console.error('Car ID is undefined.')
          return
        }

        const apiUrl = Constants.getCarById + carId
        const response = await fetch(apiUrl)

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const data = await response.json()
        setCarDetails(data)

        setMake(data.make.name)
        setVehicleType(data.vehicleType.name)
        setModel(data.model)
        setTrim(data.trim)
        setBasePrice(data.basePrice)
        setYear(data.year)
        setUsedCondition(data.type)
        setMileage(data.mileage)
        setRustProofingChecked(data.rustProofing === 'YES')
        setRustProofingDetails(data.rustProofingDetails || '')
        setPaintProtectionChecked(data.paintProtection === 'YES')
        setPaintProtectionDetails(data.paintProtectionDetails || '')
        setFabricProtectionChecked(data.fabricProtection === 'YES')
        setfabricProtectionDetails(data.fabricProtectionDetails || '')
        setWindowItchingChecked(data.windowTinting === 'YES')
        setWindowTintingDetails(data.windowTintingDetails || '')
        setOtherProtectionChecked(data.otherProtectionPackages === 'YES')
        setOtherProtectionPackagesdetails(data.otherProtectionPackagesdetails || '')
        setComprehensiveWarrantyChecked(data.comprehensiveWarranty === 'YES')
        setComprehensiveWarrantyDetails(data.comprehensiveWarrantyDetails || '')
        setPowerTrainWarrantyChecked(data.powerTrainWarranty === 'YES')
        setPowerTrainWarrantyDetails(data.powerTrainWarrantyDetails || '')
        setTotalInsuranceChecked(data.totalInsurance === 'YES')
        setTotalInsuranceDetails(data.totalInsuranceDetails || '')
        setEstimatedTotalTaxesAndFeesChecked(data.estimatedTotalTaxesAndFees === 'YES')
        setEstimatedTotalTaxesAndFeesDetails(data.estimatedTotalTaxesAndFeesDetails || '')
        setAdminFeeChecked(data.adminFee === 'YES')
        setAdminFeeDetails(data.adminFeeDetails || '')
        setAirTaxChecked(data.airTax === 'YES')
        setAirTaxdetails(data.airTaxdetails || '')
        setGasolineChecked(data.gasoline === 'YES')
        setGasolineDetails(data.gasolineDetails || '')
        setOmvicFeeChecked(data.omvicFee === 'YES')
        setOmvicFeedetails(data.omvicFeedetails || '')
        setLienRegistrationFeeChecked(data.lienRegistrationFee === 'YES')
        setLienRegistrationFeeDetails(data.lienRegistrationFeeDetails || '')
        setLicenseFeeChecked(data.licenseFee === 'YES')
        setLicenseFeeDetails(data.licenseFeeDetails || '')
        setOtherChecked(data.other === 'YES')
        setOtherDetails(data.otherDetails || '')
        setPayableOnDeliveryChecked(data.payableOnDelivery === 'YES')
        setPayableOnDeliveryDetails(data.payableOnDeliveryDetails || '')
        setTaxChecked({
          gstChecked: data.taxRate === 'GST',
          hstChecked: data.taxRate === 'HST',
        })
        setTaxRatePercentage(data.taxRatePercentage || '')
        setNote(data.note || '')
        setTireAndWheelChecked(data.tireAndWheel === 'YES')
        setTireAndWheelDetails(data.tireAndWheelDetails || '')
        setSideStepsChecked(data.sideSteps === 'YES')
        setSideStepsdetails(data.sideStepsdetails || '')
        setRoofRacksChecked(data.roofRacks === 'YES')
        setRoofRacksDetails(data.roofRacksDetails || '')
        setTrailerPackagesChecked(data.trailerPackages === 'YES')
        settrailerPackagesdetails(data.trailerPackagesdetails || '')
        setSprayLinerChecked(data.sprayLiner === 'YES')
        setSprayLinerdetails(data.sprayLinerdetails || '')
      } catch (error) {
        console.error('Error fetching car details:', error.message)
      }
    }

    fetchCarDetails()
  }, [carId])

  const handleEdit = () => {
    if (!make) {
      MySwal.fire({
        icon: 'error',
        title: ' Error',
        text: 'Make is mandatory.',
      })
      return
    }
    if (!model) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Model is mandatory ',
      })
      return
    }
    if (!trim) {
      MySwal.fire({
        icon: 'error',
        title: ' Error',
        text: 'Trim is mandatory ',
      })
      return
    }
    if (!year) {
      MySwal.fire({
        icon: 'error',
        title: ' Error',
        text: 'Year is mandatory .',
      })
      return
    }
    if (!usedCondition) {
      MySwal.fire({
        icon: 'error',
        title: ' Error',
        text: 'Type is mandatory .',
      })
      return
    }

    const editData = {
      make: make,
      vehicleType : vehicleType,
      model: model,
      trim: trim,
      year: year,
      type: usedCondition,
      basePrice:basePrice,
      mileage: (usedCondition === 'CertifiedPreOwned' || usedCondition === 'PreOwned'  ) ? mileage : 'Not Available',
      rustProofing: rustProofingChecked ? 'YES' : 'NO',
      rustProofingDetails: rustProofingChecked ? rustProofingDetails : 'Not Available',
      paintProtection: paintProtectionChecked ? 'YES' : 'NO',
      paintProtectionDetails: paintProtectionChecked ? paintProtectionDetails : 'Not Available',
      fabricProtection: fabricProtectionChecked ? 'YES' : 'NO',
      fabricProtectionDetails: fabricProtectionChecked ? fabricProtectionDetails : 'Not Available',
      windowTinting: windowItchingChecked ? 'YES' : 'NO',
      windowTintingDetails: windowItchingChecked ? windowTintingDetails : 'Not Available',
      otherProtectionPackages: otherProtectionChecked ? 'YES' : 'NO',
      otherProtectionPackagesdetails: otherProtectionChecked
        ? otherProtectionPackagesdetails
        : 'Not Available',
      comprehensiveWarranty: comprehensiveWarrantyChecked ? 'YES' : 'NO',
      comprehensiveWarrantyDetails: comprehensiveWarrantyChecked
        ? comprehensiveWarrantyDetails
        : 'Not Available',
      powerTrainWarranty: powerTrainWarrantyChecked ? 'YES' : 'NO',
      powerTrainWarrantyDetails: powerTrainWarrantyChecked
        ? powerTrainWarrantyDetails
        : 'Not Available',
      totalInsurance: totalInsuranceChecked ? 'YES' : 'NO',
      totalInsuranceDetails: totalInsuranceChecked ? totalInsuranceDetails : 'Not Available',
      estimatedTotalTaxesAndFees: estimatedTotalTaxesAndFeesChecked ? 'YES' : 'NO',
      estimatedTotalTaxesAndFeesDetails: estimatedTotalTaxesAndFeesChecked
        ? estimatedTotalTaxesAndFeesDetails
        : 'Not Available',
      adminFee: adminFeeChecked ? 'YES' : 'NO',
      adminFeeDetails: adminFeeChecked ? adminFeeDetails : 'Not Available',
      airTax: airTaxChecked ? 'YES' : 'NO',
      airTaxdetails: airTaxChecked ? airTaxdetails : 'Not Available',
      gasoline: gasolineChecked ? 'YES' : 'NO',
      gasolineDetails: gasolineChecked ? gasolineDetails : 'Not Available',
      omvicFee: omvicFeeChecked ? 'YES' : 'NO',
      omvicFeedetails: omvicFeeChecked ? omvicFeedetails : 'Not Available',
      lienRegistrationFee: lienRegistrationFeeChecked ? 'YES' : 'NO',
      lienRegistrationFeeDetails: lienRegistrationFeeChecked
        ? lienRegistrationFeeDetails
        : 'Not Available',
      licenseFee: licenseFeeChecked ? 'YES' : 'NO',
      licenseFeeDetails: licenseFeeChecked ? licenseFeeDetails : 'Not Available',
      other: otherChecked ? 'YES' : 'NO',
      otherDetails: otherChecked ? otherDetails : 'Not Available',
      payableOnDelivery: payableOnDeliveryChecked ? 'YES' : 'NO',
      payableOnDeliveryDetails: payableOnDeliveryChecked
        ? payableOnDeliveryDetails
        : 'Not Available',
      taxRate: taxChecked.gstChecked ? 'GST' : 'HST',
      taxRatePercentage: taxRatePercentage,
      note: note,
      tireAndWheel: tireAndWheelChecked ? 'YES' : 'NO',
      tireAndWheelDetails: tireAndWheelChecked ? tireAndWheelDetails : 'Not Available',
      sideSteps: sideStepsChecked ? 'YES' : 'NO',
      sideStepsdetails: sideStepsChecked ? sideStepsdetails : 'Not Available',
      roofRacks: roofRacksChecked ? 'YES' : 'NO',
      roofRacksDetails: roofRacksChecked ? roofRacksDetails : 'Not Available',
      trailerPackages: trailerPackagesChecked ? 'YES' : 'NO',
      trailerPackagesdetails: trailerPackagesChecked ? trailerPackagesdetails : 'Not Available',
      sprayLiner: sprayLinerChecked ? 'YES' : 'NO',
      sprayLinerdetails: sprayLinerChecked ? sprayLinerdetails : 'Not Available',
    }

    const apiUrlEdit = Constants.editCarById + carId
    fetch(apiUrlEdit, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editData),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        MySwal.fire({
          icon: 'success',
          title: 'Car Edited Successfully!',
          showConfirmButton: false,
          timer: 1500,
        })
        const responseData = await response.json()
        setResponse(responseData)
        setStep(5)
      })
      .catch((error) => {
        console.error('Error editing car:', error.message)
      })
  }

  const steps = [
    { title: 'Basic Details' },
    { title: 'Protection Packages' },
    { title: 'Wrranty, Insurance & Taxes' },
    { title: 'Extras' },

    { title: 'Finish' },
  ]

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div>
                  <h4 className=" text-info m-1">EDIT CAR DETAILS</h4>
                  <h6 className="mx-2">Fill all the form to go to next step</h6>
                </div>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Home</a>
                    </li>

                    <li className="breadcrumb-item active text-info">Edit Car </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border my-2">
                <div className="card-body">
                  <Stepper
                    steps={steps}
                    activeStep={step - 1}
                    activeColor="#0DCAF0" // Change this color to match your active step color
                    completeColor="#0DCAF0" // Change this color to match your completed step color
                    defaultBarColor="#E0E0E0" // Change this color to match your step bar color
                    completeBarColor="#0DCAF0" // Change this color to match your completed step bar color
                    circleTop={0} // Adjust the vertical alignment of the circle icons
                    activeStepCircleSize={40} // Adjust the size of the active step circle
                  />

                  {step === 5 ? (
                    <div className="m-4 p-">
                      <div
                        className="progress m-3"
                        role="progressbar"
                        aria-label="Animated striped example"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated  bg-info"
                          style={{ width: '100%' }}
                        ></div>
                      </div>
                      <h4></h4>
                      <div className="step">
                        <h1></h1>
                        <p>Step 5 of 5</p>
                      </div>
                      <h2 className="text-success text-center">
                        <strong>SUCCESS !</strong>
                      </h2>
                      <br />
                      {/* <div className="row justify-content-center mx-2">
                        <div className="col-3">
                          <img
                            src="https://i.imgur.com/GwStPmg.png"
                            style={{ height: '150px', width: '150px' }}
                            className="fit-image"
                          />
                        </div>
                      </div> */}
                      <br />
                      <div className="row justify-content-center">
                        <div className="col-8 text-center">
                          <h5 className="purple-text text-center">Car Edited Successfully!</h5>

                          <ul className="list-group">
                            <li className="list-group-item">
                              {' '}
                              <strong>Model:</strong> {response.model}
                            </li>
                            <li className="list-group-item">
                              <strong>Trim:</strong> {response.trim}
                            </li>
                            <li className="list-group-item">
                              {' '}
                              <strong>Year:</strong> {response.year}
                            </li>
                            <li className="list-group-item">
                              {' '}
                              <strong>Type:</strong> {response.type}{' '}
                              <strong className="ms-3">Mileage:</strong> {response.mileage}
                            </li>

                            <li className="list-group-item ">
                              {' '}
                              <strong>Rust Proofing:</strong> {response.rustProofing}{' '}
                              <strong className="ms-3">Rust Proofing Details: </strong>{' '}
                              {response.rustProofingDetails}
                            </li>

                            <li className="list-group-item">
                              <strong>Paint Protection:</strong> {response.paintProtection}{' '}
                              <strong className="ms-3">Paint Protection Details:</strong>{' '}
                              {response.paintProtectionDetails}
                            </li>

                            <li className="list-group-item">
                              <strong>Fabric Protection:</strong> {response.fabricProtection}{' '}
                              <strong className="ms-3">Fabric Protection Details : </strong>{' '}
                              {response.fabricProtectionDetails}{' '}
                            </li>
                            <li className="list-group-item">
                              <strong>Window Tinting:</strong> {response.windowTinting}{' '}
                              <strong className="ms-3">Window Tinting Details:</strong>{' '}
                              {response.windowTintingDetails}{' '}
                            </li>

                            <li className="list-group-item">
                              <strong>Other Protection Packages:</strong>{' '}
                              {response.otherProtectionPackages}
                            </li>
                            <li className="list-group-item">
                              <strong>Comprehensive Warranty:</strong>{' '}
                              {response.comprehensiveWarranty}{' '}
                              <strong className="ms-3">Comprehensive Warranty Details:</strong>{' '}
                              {response.comprehensiveWarrantyDetails}
                            </li>

                            <li className="list-group-item">
                              <strong>Power Train Warranty: </strong>
                              {response.powerTrainWarranty}
                              <strong className="ms-3">Power Train Warranty Details:</strong>
                              {response.powerTrainWarrantyDetails}{' '}
                            </li>

                            <li className="list-group-item">
                              <strong>Total Insurance:</strong> {response.totalInsurance}
                              <strong className="ms-3">Total Insurance Details:</strong>{' '}
                              {response.totalInsuranceDetails}
                            </li>

                            <li className="list-group-item">
                              <strong>Estimated Total Taxes And Fees:</strong>{' '}
                              {response.estimatedTotalTaxesAndFees}{' '}
                            </li>
                            <li className="list-group-item">
                              <strong>Estimated Total Taxes And Fees Details: </strong>
                              {response.estimatedTotalTaxesAndFeesDetails}
                            </li>
                            <li className="list-group-item">
                              <strong>Admin Fee:</strong> {response.adminFee}{' '}
                              <strong className="ms-3">Admin Fee Details:</strong>
                              {response.adminFeeDetails}
                            </li>

                            <li className="list-group-item">
                              <strong>Air Tax:</strong> {response.airTax}{' '}
                              <strong className="ms-3">Air Tax Details:</strong>{' '}
                              {response.airTaxdetails}{' '}
                            </li>

                            <li className="list-group-item">
                              <strong>Gasoline:</strong> {response.gasoline}{' '}
                              <strong className="ms-3">Gasoline Details:</strong>
                              {response.gasolineDetails}{' '}
                            </li>

                            <li className="list-group-item">
                              <strong>OMVIC Fee:</strong> {response.omvicFee}{' '}
                              <strong className="ms-3">OMVIC Fees Details:</strong>{' '}
                              {response.omvicFeedetails}{' '}
                            </li>

                            <li className="list-group-item">
                              <strong>Lien Registration Fee:</strong> {response.lienRegistrationFee}
                              <strong className="ms-3">Lien Registration Fee Details:</strong>{' '}
                              {response.lienRegistrationFeeDetails}
                            </li>

                            <li className="list-group-item">
                              <strong>License Fee: </strong>
                              {response.licenseFee}{' '}
                              <strong className="ms-3">License Fee Details:</strong>
                              {response.licenseFeeDetails}{' '}
                            </li>

                            <li className="list-group-item">
                              <strong>Other:</strong> {response.other}{' '}
                              <strong className="ms-3">Other Details:</strong>{' '}
                              {response.otherDetails}
                            </li>

                            <li className="list-group-item">
                              <strong>Payable On Delivery:</strong> {response.payableOnDelivery}{' '}
                              <strong className="ms-3">Payable On Delivery Details:</strong>
                              {response.payableOnDeliveryDetails}{' '}
                            </li>

                            <li className="list-group-item">
                              <strong>Tax Rate:</strong> {response.taxRate}{' '}
                              <strong className="ms-3">Tax Rate Percentage:</strong>
                              {response.taxRatePercentage}{' '}
                            </li>

                            <li className="list-group-item">
                              <strong>Note:</strong> {response.note}{' '}
                            </li>
                            <li className="list-group-item">
                              <strong>Tire And Wheel:</strong> {response.tireAndWheel}{' '}
                              <strong className="ms-3">Tire And Wheel Details:</strong>
                              {response.tireAndWheelDetails}{' '}
                            </li>

                            <li className="list-group-item">
                              <strong>Side Steps:</strong> {response.sideSteps}{' '}
                              <strong className="ms-3">Side Steps Details:</strong>{' '}
                              {response.sideStepsdetails}{' '}
                            </li>

                            <li className="list-group-item">
                              <strong>Roof Racks: </strong>
                              {response.roofRacks}{' '}
                              <strong className="ms-3">Roof Racks Details:</strong>{' '}
                              {response.roofRacksDetails}{' '}
                            </li>

                            <li className="list-group-item">
                              <strong>Trailer Packages: </strong>
                              {response.trailerPackages}{' '}
                              <strong className="ms-3">Trailer Packages Details:</strong>
                              {response.trailerPackagesdetails}{' '}
                            </li>

                            <li className="list-group-item">
                              <strong>Spray Liner:</strong> {response.sprayLiner}{' '}
                              <strong className="ms-3">Spray Liner Details:</strong>{' '}
                              {response.sprayLinerdetails}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <form>
                      {step === 1 && (
                        <div className="m-4">
                          <div
                            className="progress m-3"
                            role="progressbar"
                            aria-label="Animated striped example"
                            aria-valuenow="20"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated  bg-info"
                              style={{ width: '12%' }}
                            ></div>
                          </div>

                          <div className="step">
                            <h4>Basic Details</h4>
                            <p>Step 1 of 5</p>
                          </div>

                          <div className="col-lg-12 mb-3">
                            <div className="form-group">
                              <label>Condition</label>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id="New"
                                  name="usedCondition"
                                  value="New"
                                  onChange={handleUsedCondition}
                                  checked={usedCondition === 'New'}
                                />
                                <label className="form-check-label" htmlFor="USED">
                                  New
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id="PreOwned"
                                  name="usedCondition"
                                  value="PreOwned"
                                  onChange={handleUsedCondition}
                                  checked={usedCondition === 'PreOwned'}
                                />
                                <label className="form-check-label" htmlFor="NEW">
                                Pre-Owned
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id="CertifiedPreOwned"
                                  name="usedCondition"
                                  value="CertifiedPreOwned"
                                  onChange={handleUsedCondition}
                                  checked={usedCondition === 'CertifiedPreOwned'}
                                />
                                <label className="form-check-label" htmlFor="NEW">
                                Certified Pre-Owned
                                </label>
                              </div>
                              
                            </div>
                          </div>

                          {(usedCondition === 'PreOwned' || usedCondition === 'CertifiedPreOwned') && (
                            <div className="col-lg-12 mb-3">
                              <div className="form-group">
                                <label>Mileage</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  aria-label="Sizing example input"
                                  aria-describedby="inputGroup-sizing-default"
                                  value={mileage}
                                  onChange={(e) => setMileage(e.target.value)}
                                  required
                                  placeholder="Mileage"
                                />
                              </div>
                            </div>
                          )}

                          <div className="col-lg-12 mb-3">
                            <div className="form-group">
                              <label>Year</label>
                              <input
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                type="text"
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                                placeholder="Year"
                                required
                              />
                            </div>
                          </div>
                      

                          <div className="col-lg-12 mb-3">
                            <div className="form-group">
                              <label>Make</label>
                              <select
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                required
                                onChange={(e) => setMake(e.target.value)}
                                value={make}
                              >
                                <option value="">Select Make</option>

                                {carMakes.map((makeOption, index) => (
                                  <option key={index} value={makeOption}>
                                    {makeOption}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3">
                            <div className="form-group">
                              <label>Vehicle Type</label>
                              <select
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                required
                                onChange={(e) => setVehicleType(e.target.value)}
                                value={vehicleType}
                              >
                                <option value="">Select Vehicle Type</option>

                                {vehicleTypes.map((vehicleTypeOption, index) => (
                                  <option key={index} value={vehicleTypeOption}>
                                    {vehicleTypeOption}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-12 mb-3">
                            <div className="form-group">
                              <label>Model</label>
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                value={model}
                                onChange={(e) => setModel(e.target.value)}
                                required
                                placeholder="Model"
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 mb-3">
                            <div className="form-group">
                              <label>Trim</label>
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                value={trim}
                                onChange={(e) => setTrim(e.target.value)}
                                required
                                placeholder="Trim"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3">
                            <div className="form-group">
                              <label>Base Price</label>
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                value={basePrice}
                                onChange={(e) => setBasePrice(e.target.value)}
                                required
                                placeholder="Base Price"
                              />
                            </div>
                          </div>

                          <button
                            type="button"
                            className="btn btn-outline-info m-2 p-2 align-right"
                            onClick={handleNext}
                          >
                            Next
                          </button>
                        </div>
                      )}
                      {step === 2 && (
                        <div className=" m-4">
                          <div
                            className="progress m-3"
                            role="progressbar"
                            aria-label="Animated striped example"
                            aria-valuenow="27"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated  bg-info"
                              style={{ width: '29%' }}
                            ></div>
                          </div>
                          <div className="step">
                            <h4>Protection Packages</h4>
                            <p>Step 2 of 5</p>
                          </div>
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-6 mb-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={rustProofingChecked}
                                    onChange={() => setRustProofingChecked(!rustProofingChecked)}
                                  />
                                  <label className="form-check-label ml-2">Rust Proofing</label>
                                  {rustProofingChecked && (
                                    <input
                                      className="form-control mt-2"
                                      type="text"
                                      placeholder="Enter details..."
                                      value={rustProofingDetails}
                                      onChange={(e) => setRustProofingDetails(e.target.value)}
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6 mb-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={paintProtectionChecked}
                                    onChange={() =>
                                      setPaintProtectionChecked(!paintProtectionChecked)
                                    }
                                  />
                                  <label className="form-check-label ml-2">Paint Protection</label>
                                  {paintProtectionChecked && (
                                    <input
                                      className="form-control mt-2"
                                      type="text"
                                      placeholder="Enter details..."
                                      value={paintProtectionDetails}
                                      onChange={(e) => setPaintProtectionDetails(e.target.value)}
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6 mb-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={fabricProtectionChecked}
                                    onChange={() =>
                                      setFabricProtectionChecked(!fabricProtectionChecked)
                                    }
                                  />
                                  <label className="form-check-label ml-2">Fabric Protection</label>
                                  {fabricProtectionChecked && (
                                    <input
                                      className="form-control mt-2"
                                      type="text"
                                      placeholder="Enter details..."
                                      value={fabricProtectionDetails}
                                      onChange={(e) => setfabricProtectionDetails(e.target.value)}
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6 mb-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={windowItchingChecked}
                                    onChange={() => setWindowItchingChecked(!windowItchingChecked)}
                                  />
                                  <label className="form-check-label ml-2">Window Etching</label>
                                  {windowItchingChecked && (
                                    <input
                                      className="form-control mt-2"
                                      type="text"
                                      placeholder="Enter details..."
                                      value={windowTintingDetails}
                                      onChange={(e) => setWindowTintingDetails(e.target.value)}
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6 mb-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={otherProtectionChecked}
                                    onChange={() =>
                                      setOtherProtectionChecked(!otherProtectionChecked)
                                    }
                                  />
                                  <label className="form-check-label ml-2">
                                    Other Protection Packages
                                  </label>
                                  {otherProtectionChecked && (
                                    <input
                                      className="form-control mt-2"
                                      type="text"
                                      placeholder="Enter details..."
                                      value={otherProtectionPackagesdetails}
                                      onChange={(e) =>
                                        setOtherProtectionPackagesdetails(e.target.value)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="button-container">
                            <button
                              type="button"
                              className="btn btn-outline-info m-2 p-2"
                              onClick={handlePrevious}
                            >
                              Previous
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-info m-2 p-2"
                              onClick={handleNext}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      )}
                      {step === 3 && (
                        <div className="m-4">
                          <div
                            className="progress m-3"
                            role="progressbar"
                            aria-label="Animated striped example"
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated  bg-info"
                              style={{ width: '51%' }}
                            ></div>
                          </div>
                          <div className="step">
                            <h4>Warranty, Insurance & Taxes</h4>
                            <p>Step 3 of 5</p>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={comprehensiveWarrantyChecked}
                                  onChange={() =>
                                    setComprehensiveWarrantyChecked(!comprehensiveWarrantyChecked)
                                  }
                                />
                                <label className="form-check-label ml-2">
                                  Comprehensive Warranty
                                </label>
                                {comprehensiveWarrantyChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={comprehensiveWarrantyDetails}
                                    onChange={(e) =>
                                      setComprehensiveWarrantyDetails(e.target.value)
                                    }
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={powerTrainWarrantyChecked}
                                  onChange={() =>
                                    setPowerTrainWarrantyChecked(!powerTrainWarrantyChecked)
                                  }
                                />
                                <label className="form-check-label ml-2">
                                  Power Train Warranty
                                </label>
                                {powerTrainWarrantyChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={powerTrainWarrantyDetails}
                                    onChange={(e) => setPowerTrainWarrantyDetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={totalInsuranceChecked}
                                  onChange={() => setTotalInsuranceChecked(!totalInsuranceChecked)}
                                />
                                <label className="form-check-label ml-2">Total Insurance</label>
                                {totalInsuranceChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={totalInsuranceDetails}
                                    onChange={(e) => setTotalInsuranceDetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={estimatedTotalTaxesAndFeesChecked}
                                  onChange={() =>
                                    setEstimatedTotalTaxesAndFeesChecked(
                                      !estimatedTotalTaxesAndFeesChecked,
                                    )
                                  }
                                />
                                <label className="form-check-label ml-2">
                                  Estimated Total Taxes & Fees
                                </label>
                                {estimatedTotalTaxesAndFeesChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={estimatedTotalTaxesAndFeesDetails}
                                    onChange={(e) =>
                                      setEstimatedTotalTaxesAndFeesDetails(e.target.value)
                                    }
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={adminFeeChecked}
                                  onChange={() => setAdminFeeChecked(!adminFeeChecked)}
                                />
                                <label className="form-check-label ml-2">Admin Fee</label>
                                {adminFeeChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={adminFeeDetails}
                                    onChange={(e) => setAdminFeeDetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={airTaxChecked}
                                  onChange={() => setAirTaxChecked(!airTaxChecked)}
                                />
                                <label className="form-check-label ml-2">Air Tax</label>
                                {airTaxChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={airTaxdetails}
                                    onChange={(e) => setAirTaxdetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={gasolineChecked}
                                  onChange={() => setGasolineChecked(!gasolineChecked)}
                                />
                                <label className="form-check-label ml-2">Gasoline</label>
                                {gasolineChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={gasolineDetails}
                                    onChange={(e) => setGasolineDetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={omvicFeeChecked}
                                  onChange={() => setOmvicFeeChecked(!omvicFeeChecked)}
                                />
                                <label className="form-check-label ml-2">OMVIC Fee</label>
                                {omvicFeeChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={omvicFeedetails}
                                    onChange={(e) => setOmvicFeedetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={lienRegistrationFeeChecked}
                                  onChange={() =>
                                    setLienRegistrationFeeChecked(!lienRegistrationFeeChecked)
                                  }
                                />
                                <label className="form-check-label ml-2">
                                  Lien Registration Fee
                                </label>
                                {lienRegistrationFeeChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={lienRegistrationFeeDetails}
                                    onChange={(e) => setLienRegistrationFeeDetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={licenseFeeChecked}
                                  onChange={() => setLicenseFeeChecked(!licenseFeeChecked)}
                                />
                                <label className="form-check-label ml-2">License Fee</label>
                                {licenseFeeChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={licenseFeeDetails}
                                    onChange={(e) => setLicenseFeeDetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={otherChecked}
                                  onChange={() => setOtherChecked(!otherChecked)}
                                />
                                <label className="form-check-label ml-2">Other</label>
                                {otherChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={otherDetails}
                                    onChange={(e) => setOtherDetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={payableOnDeliveryChecked}
                                  onChange={() =>
                                    setPayableOnDeliveryChecked(!payableOnDeliveryChecked)
                                  }
                                />
                                <label className="form-check-label ml-2">Payable on Delivery</label>
                                {payableOnDeliveryChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={payableOnDeliveryDetails}
                                    onChange={(e) => setPayableOnDeliveryDetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={taxChecked.gstChecked}
                                  onChange={() => handleCheckboxChange('gstChecked')}
                                />
                                <label className="form-check-label ml-2">GST</label>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={taxChecked.hstChecked}
                                  onChange={() => handleCheckboxChange('hstChecked')}
                                />
                                <label className="form-check-label ml-2">HST</label>
                              </div>

                              {(taxChecked.gstChecked || taxChecked.hstChecked) && (
                                <input
                                  className="form-control mt-2"
                                  type="text"
                                  placeholder="Enter details..."
                                  value={taxRatePercentage}
                                  onChange={(e) => setTaxRatePercentage(e.target.value)}
                                />
                              )}
                            </div>

                            <div className="col-lg-12 mb-3">
                              <label>Note</label>
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  rows="3"
                                  placeholder="Enter details..."
                                  value={note}
                                  onChange={(e) => setNote(e.target.value)}
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="button-container">
                            <button
                              type="button"
                              className="btn btn-outline-info m-2 p-2"
                              onClick={handlePrevious}
                            >
                              Previous
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-info m-2 p-2"
                              onClick={handleNext}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      )}

                      {step === 4 && (
                        <div className="m-4">
                          <div
                            className="progress m-3"
                            role="progressbar"
                            aria-label="Animated striped example"
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated  bg-info"
                              style={{ width: '72%' }}
                            ></div>
                          </div>
                          <div className="step">
                            <h4>Extras</h4>
                            <p>Step 4 of 5</p>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={tireAndWheelChecked}
                                  onChange={() => setTireAndWheelChecked(!tireAndWheelChecked)}
                                />
                                <label className="form-check-label ml-2">Tire and Wheel</label>
                                {tireAndWheelChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={tireAndWheelDetails}
                                    onChange={(e) => setTireAndWheelDetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={sideStepsChecked}
                                  onChange={() => setSideStepsChecked(!sideStepsChecked)}
                                />
                                <label className="form-check-label ml-2">Side Steps</label>
                                {sideStepsChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={sideStepsdetails}
                                    onChange={(e) => setSideStepsdetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={roofRacksChecked}
                                  onChange={() => setRoofRacksChecked(!roofRacksChecked)}
                                />
                                <label className="form-check-label ml-2">Roof Racks</label>
                                {roofRacksChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={roofRacksDetails}
                                    onChange={(e) => setRoofRacksDetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={trailerPackagesChecked}
                                  onChange={() =>
                                    setTrailerPackagesChecked(!trailerPackagesChecked)
                                  }
                                />
                                <label className="form-check-label ml-2">Trailer Packages</label>
                                {trailerPackagesChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={trailerPackagesdetails}
                                    onChange={(e) => settrailerPackagesdetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={sprayLinerChecked}
                                  onChange={() => setSprayLinerChecked(!sprayLinerChecked)}
                                />
                                <label className="form-check-label ml-2">Spray Liners</label>
                                {sprayLinerChecked && (
                                  <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter details..."
                                    value={sprayLinerdetails}
                                    onChange={(e) => setSprayLinerdetails(e.target.value)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="button-container">
                            <button
                              type="button"
                              className="btn btn-outline-info m-2 p-2"
                              onClick={handlePrevious}
                            >
                              Previous
                            </button>
                            {/* <button className="btn btn-outline-info m-2 p-2">Submit</button> */}
                            <button
                              type="button"
                              className="btn btn-outline-info m-2 p-2"
                              onClick={handleEdit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCar
