const prefix = 'https://api.icarbuyer.co/api/'
// const prefix = 'http://localhost:6060/api/'
// const isLocalhost = window.location.hostname === 'localhost';
// const prefix = isLocalhost ? 'http://localhost:6060/api/' : 'https://api.icarbuyer.co/api/';

export const Constants = {
  adminLogIn: prefix + 'admin/login',
  getAdminDetails: prefix + 'admin',
  registerOrUpdateAdmin: prefix + 'admin/register',

  //users
  getAllUsers: prefix + 'auth/users',
  getUserById: prefix + 'auth/users/',
  addNewUser: prefix + 'auth/register',
  deleteUser: prefix + 'auth/users/',

  //dealers
  addNewDealer: prefix + 'dealer/register',
  getAllDealers: prefix + 'dealer/dealers',
  updateDealerStatus: prefix + 'dealer/dealers/',
  getDealerById: prefix + 'dealer/dealers/',


  createCarList: prefix + 'car/car',
  getAllCar: prefix + 'car/car',
  getCarById: prefix + 'car/car/',
  editCarById: prefix + 'car/car/',
  deleteCarById: prefix + 'car/car/',

  getAllMakes: prefix + 'make/make',
  updateMakeStatus: prefix + 'make/make/',
  deleteMakeByID: prefix + 'make/make/',
  addNewMake: prefix + 'make/make',
  getMakeByID: prefix + 'make/make/',
  updateMakeByID: prefix + 'make/make/',
  postImageFile: prefix + 'files/upload',

  getAllVehicleTypes: prefix + 'vehicle/vehicleType',
  updateVehicleTypeStatus: prefix + 'vehicle/vehicleType/',
  deleteVehicleTypebyId: prefix + 'vehicle/vehicleType/',
  addNewVehicleType: prefix + 'vehicle/vehicleType',
  getVehicleTypeById: prefix + 'vehicle/vehicleType/',
  updateVehicleType: prefix + 'vehicle/vehicleType/',

  getVehicleTypesByMakeName : prefix + 'make/make/search?q=',

  //financial
  getAllFinanceEnquiry : prefix + 'enquiry/enquiry/all',
  getFinanceEnquiryById : prefix + 'enquiry/enquiry/',
  deleteFinanceEnquiryById : prefix + 'enquiry/enquiry/',

  getAboutUs: prefix + 'aboutUs/get',
  postOrUpdateAboutUs: prefix + 'aboutUs/addOrUpdate',

  getpolicy: prefix + 'policy-service',
  postOrUpdatePolicy: prefix + 'policy-service',
}

export const imageUrl = 'https://api.icarbuyer.co/static/'
