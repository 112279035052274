import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Constants } from 'src/Constants';
import Swal from 'sweetalert2';
import { Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const PolicyAndServices = () => {
   const [formData, setFormData] = useState({
      serviceHeading: '',
      serviceDescription: '',
      policyHeading: '',
      policyDescription: '',
   });
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      fetchPolicyData();
   }, []);

   const fetchPolicyData = () => {
      setLoading(true); // Set loading to true when fetching data
      fetch(Constants.getpolicy)
         .then((response) => response.json())
         .then((data) => {
            setFormData(data);
            setLoading(false); // Set loading to false once data is fetched
         })
         .catch((error) => {
            console.error('Error fetching data:', error);
            setLoading(false); // Set loading to false in case of error
         });
   };

   const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevData) => ({
         ...prevData,
         [name]: value,
      }));
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      fetch(Constants.postOrUpdatePolicy, {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(formData),
      })
         .then((response) => response.json())
         .then((data) => {
            Swal.fire({
               icon: 'success',
               title: 'Success',
               text: 'Data updated successfully',
            });
         })
         .catch((error) => {
            console.error('Error updating data:', error);
            Swal.fire({
               icon: 'error',
               title: 'Error',
               text: 'Failed to update  data',
            });
         });
   };

   return (
      <div className="main-content">
         <div className="page-content">
            <div className="container-fluid">
               <Row>
                  <Col>
                     <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="mb-0 text-info">Privacy Policy and Terms & Condition  </h4>
                        <div className="page-title-right">
                           <ol className="breadcrumb m-0">
                              <li className="breadcrumb-item">
                                 <Link to="/dashboard">Home</Link>
                              </li>
                              <li className="breadcrumb-item active text-info">Policy and Cnditions</li>
                           </ol>
                        </div>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col md={16}>
                     <div className="card custom-shadow rounded-lg border my-2">
                        <div className="card-body">
                           {loading ? ( 
                              <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                                 <Spinner animation="border" />
                              </div>
                           ) : (
                              <Form onSubmit={handleSubmit}>
                                   <Form.Group controlId="policyHeading">
                                    <h5>Privacy Policy Heading</h5>
                                    <Form.Control
                                       type="text"
                                       name="policyHeading"
                                       value={formData?.policyHeading}
                                       onChange={handleChange}
                                    />
                                 </Form.Group>
                                 <Form.Group controlId="policyDescription">
                                    <h5>Privacy Policy Description</h5>
                                    <CKEditor
                                       editor={ClassicEditor}
                                       data={formData?.policyDescription}
                                       onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setFormData({ ...formData, policyDescription: data });
                                       }}
                                    />
                                 </Form.Group>
                                 <Form.Group controlId="serviceHeading" className='mt-4'>
                                    <h5>Terms & Condition Heading</h5>
                                    <Form.Control
                                       type="text"
                                       name="serviceHeading"
                                       value={formData?.serviceHeading}
                                       onChange={handleChange}
                                    />
                                 </Form.Group>
                                 <Form.Group controlId="serviceDescription" >
                                    <h5>Terms & Condition Description</h5>
                                    <CKEditor
                                       editor={ClassicEditor}
                                       data={formData?.serviceDescription}
                                       onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setFormData({ ...formData, serviceDescription: data });
                                       }}
                                    />
                                 </Form.Group>
                               
                                 <Button variant="primary" type="submit" className="my-2">
                                    Save Changes
                                 </Button>
                              </Form>
                           )}
                        </div>
                     </div>
                  </Col>
               </Row>
            </div>
         </div>
      </div>
   );
};

export default PolicyAndServices;
