import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaEye, FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import { Container, Row, Col, Table, Button, Spinner, Card } from 'react-bootstrap'
import { Constants } from 'src/Constants'

const ViewFinance = () => {
  const { id } = useParams()
  const [financeData, setFinanceData] = useState(null)
  const [loading, setLoading] = useState(true)
  let formattedDate = null

  useEffect(() => {
    fetch(Constants.getFinanceEnquiryById + id)
      .then((response) => response.json())
      .then((data) => {
        setFinanceData(data.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching finance data:', error)
        setLoading(false)
      })
  }, [id])
  const createdAt = financeData?.createdAt

  if (financeData?.createdAt) {
    const createdAt = financeData.createdAt
    const dateObj = new Date(createdAt)
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const day = dateObj.getDate()
    const monthIndex = dateObj.getMonth()
    const year = dateObj.getFullYear()
    const hours = dateObj.getHours()
    const minutes = dateObj.getMinutes()
    let period = 'AM'
    let hour = hours
    if (hour >= 12) {
      period = 'PM'
      hour = hour - 12
    }
    if (hour === 0) {
      hour = 12
    }
    formattedDate =
      day +
      ' ' +
      months[monthIndex] +
      ' ' +
      year +
      ', ' +
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ' ' +
      period
  }
  const handleGoBack = () => {
    window.history.back()
  }
  function isValidNumber(value) {
    return !isNaN(parseFloat(value))
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">FINANCE ENQUIRY</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Finance Enquiry</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          ) : (
            <Row className="m-2">
              <Col xs={12}>
                <div className="card custom-shadow rounded-lg border">
                  <div className="card-body">
                    <h5 className="mb-3"> Personal Information</h5>
                    <Table striped bordered hover>
                      <tbody>
                        <tr>
                          <td>Name:</td>
                          <td>
                            {financeData?.userId?.firstName} {financeData?.userId?.lastName}
                          </td>
                        </tr>
                        <tr>
                          <td>Email:</td>
                          <td>{financeData?.userId?.emailAddresses}</td>
                        </tr>
                        <tr>
                          <td>Mobile:</td>
                          <td>{financeData?.userId?.contactNumbers}</td>
                        </tr>
                        <tr>
                          <td>Enquiry Date & Time:</td>
                          <td>{formattedDate}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <h5 className="mb-3 mt-4">
                      {' '}
                      Cash, Finance or Lease : {financeData?.cashFinanceLease}
                    </h5>
                    {['Finance', 'Lease'].includes(financeData?.cashFinanceLease) && (
                      <>
                        <h5 className="mb-3 mt-4">
                          Credit Pre Approval : {financeData?.creditPreApproval}
                        </h5>
                        {financeData?.creditPreApproval === 'Yes' && (
                          <Table striped bordered hover>
                            <tbody>
                              <tr>
                                <td> User Currently Employed :</td>
                                <td>{financeData?.currentlyEmployed || 'N/A'}</td>
                              </tr>
                              <tr>
                                <td>Since How Long Employed:</td>
                                <td>{financeData?.howLongEmployed || 'N/A'} Years</td>
                              </tr>
                              <tr>
                                <td>Gross Income of User:</td>
                                <td>$ {financeData?.grossIncome || 'N/A'}</td>
                              </tr>
                              <tr>
                                <td>Credit Standing:</td>
                                <td>{financeData?.creditStanding || 'N/A'}</td>
                              </tr>
                              <tr>
                                <td>Does User Have a Cosigner:</td>
                                <td>{financeData?.coSighner || 'N/A'}</td>
                              </tr>
                              <tr>
                                <td>User Address:</td>
                                <td>{financeData?.address || 'N/A'}</td>
                              </tr>
                              <tr>
                                <td>User House Status:</td>
                                <td>{financeData?.housingOwnStatus || 'N/A'}</td>
                              </tr>
                              <tr>
                                <td>House Rent (If Applicable):</td>
                                <td>{financeData?.houseRent || 'N/A'}</td>
                              </tr>
                              <tr>
                                <td>Time Lived There :</td>
                                <td>{financeData?.timeLiveStatus || 'N/A'} Years</td>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                      </>
                    )}

                    <h5 className="mb-3 mt-4">
                      {' '}
                      With / Without Data : {financeData?.withData ? 'With Data' : ' Without Data '}
                    </h5>
                    <h5 className="mb-3 mt-4"> Vehicle Information</h5>
                    <Table striped bordered hover>
                      <tbody>
                        <tr>
                          <td>Vehicle Type :</td>
                          <td> {financeData?.type}</td>
                        </tr>
                        <tr>
                          <td>Year :</td>
                          <td>{financeData?.year}</td>
                        </tr>
                        <tr>
                          <td>Make :</td>
                          <td>{financeData?.make}</td>
                        </tr>
                        <tr>
                          <td>Model :</td>
                          <td>{financeData?.model}</td>
                        </tr>
                        <tr>
                          <td>Trim :</td>
                          <td>{financeData?.trim}</td>
                        </tr>
                        <tr>
                          <td>Mileage :</td>
                          <td>{financeData?.mileage} KM</td>
                        </tr>
                      </tbody>
                    </Table>
                    {['Finance', 'Cash'].includes(financeData?.cashFinanceLease) && (
                      <>
                        <h5 className="mb-3 mt-4"> Estimate Sales Price</h5>
                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>Sale Price :</td>
                              <td>$ {financeData?.salePrice}</td>
                            </tr>
                            <tr>
                              <td> Estimate Sale Price :</td>
                              <td>$ {financeData?.estimatedSalesPrice} </td>
                            </tr>
                            <tr>
                              <td>Freight :</td>
                              <td>$ {financeData?.freight}</td>
                            </tr>
                            <tr>
                              <td>Rebate :</td>
                              <td>$ {financeData?.rebate}</td>
                            </tr>

                            <tr>
                              <td>Discount :</td>
                              <td>$ {financeData?.discount}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    )}
                    {financeData?.cashFinanceLease === 'Lease' && (
                      <>
                        <h5 className="mb-3 mt-4"> Capitalized Cost (MSRP)</h5>
                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>Capitalized Cost (MSRP) :</td>
                              <td>$ {financeData?.leaseSalePrice}</td>
                            </tr>
                            <tr>
                              <td>Estimated Sale Price :</td>
                              <td> $ {financeData?.estimatedLeaseSalePrice} </td>
                            </tr>
                            <tr>
                              <td>Freight :</td>
                              <td>$ {financeData?.leaseFreight}</td>
                            </tr>
                          </tbody>
                        </Table>
                        <h5 className="mb-3 mt-4"> Estimated Residual Value</h5>
                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>Residual Value :</td>
                              <td>$ {financeData?.buyOut}</td>
                            </tr>
                            <tr>
                              <td>Expected KM :</td>
                              <td> {financeData?.allowedKM} KM</td>
                            </tr>
                          </tbody>
                        </Table>
                        <h5 className="mb-3 mt-4">Capital Cost Reduction</h5>
                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>Capital Cost Reduction :</td>
                              <td>$ {financeData?.capitalCostReduction}</td>
                            </tr>
                            <tr>
                              <td>Rebate:</td>
                              <td> $ {financeData?.leaseRebate} </td>
                            </tr>
                            <tr>
                              <td>Discount:</td>
                              <td> $ {financeData?.leaseDiscount} </td>
                            </tr>
                            <tr>
                              <td>Downpayment:</td>
                              <td> $ {financeData?.leaseDownpayment} </td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    )}

                    <h5 className="mb-3 mt-4">Estimated Optional Extras</h5>

                    <Table striped bordered hover>
                      <tbody>
                        <tr>
                          <td>Total Extras :</td>
                          <td>$ {financeData?.totalExtras}</td>
                        </tr>
                        <tr>
                          <td>Tire & Wheel Package :</td>
                          <td>$ {financeData?.tireAndWheel}</td>
                        </tr>
                        <tr>
                          <td>Floor Mats :</td>
                          <td>$ {financeData?.sideSteps}</td>
                        </tr>
                        <tr>
                          <td>Truck Bed Liner :</td>
                          <td>$ {financeData?.roofRacks}</td>
                        </tr>
                        <tr>
                          <td>Trailer Packages :</td>
                          <td>$ {financeData?.trailerPackages}</td>
                        </tr>
                        <tr>
                          <td>Window Tinting :</td>
                          <td>$ {financeData?.sprayLiner}</td>
                        </tr>
                        <tr>
                          <td>Other Extras :</td>
                          <td>$ {financeData?.otherExtras}</td>
                        </tr>
                      </tbody>
                    </Table>

                    <h5 className="mb-3 mt-4"> Protection Packages & Extras </h5>
                    <Table striped bordered hover>
                      <tbody>
                        <tr>
                          <td>Estimated Protection Packages And Extras :</td>
                          <td>$ {financeData?.estimatedProtectionPackagesAndExtras}</td>
                        </tr>
                        <tr>
                          <td>Ceramic Coating :</td>
                          <td>$ {financeData?.packagesAndextras}</td>
                        </tr>
                        <tr>
                          <td>Rust Proofing :</td>
                          <td>$ {financeData?.rustProofing}</td>
                        </tr>
                        <tr>
                          <td>Paint Protection :</td>
                          <td>$ {financeData?.paintProtection}</td>
                        </tr>
                        <tr>
                          <td>Fabric Protection :</td>
                          <td>$ {financeData?.fabricProtection}</td>
                        </tr>
                        <tr>
                          <td>Security System :</td>
                          <td>$ {financeData?.windowEtching}</td>
                        </tr>
                        <tr>
                          <td>Other Protection Packages :</td>
                          <td>$ {financeData?.otherProtectionPackages}</td>
                        </tr>
                      </tbody>
                    </Table>
                    {['Finance', 'Cash'].includes(financeData?.cashFinanceLease) && (
                      <>
                        <h5 className="mb-3 mt-4">Warranties</h5>

                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>Total Warranties :</td>
                              <td>$ {financeData?.totalWarranties}</td>
                            </tr>
                            <tr>
                              <td>Extended Warranty :</td>
                              <td>$ {financeData?.comprehensiveWarranty}</td>
                            </tr>
                            <tr>
                              <td>Power Train Warranty :</td>
                              <td>$ {financeData?.powerTrainWarranty}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    )}

                    {financeData?.cashFinanceLease === 'Lease' && (
                      <>
                        <h5 className="mb-3 mt-4">Estimated Maintenance & Lease Protection</h5>

                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>Estimated Maintenance & Lease protection :</td>
                              <td>$ {financeData?.maintenanceAndLeaseProtection}</td>
                            </tr>
                            <tr>
                              <td> Maintenance Package :</td>
                              <td>$ {financeData?.maintenanceProtection}</td>
                            </tr>
                            <tr>
                              <td>Lease Protection Package:</td>
                              <td>$ {financeData?.leaseProtection}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    )}

                    <h5 className="mb-3 mt-4">Insurance</h5>

                    <Table striped bordered hover>
                      <tbody>
                        <tr>
                          <td>Total Insurance :</td>
                          <td>$ {financeData?.totalInsurance}</td>
                        </tr>
                        <tr>
                          <td>Life & Disability :</td>
                          <td>$ {financeData?.lifeAndDisability}</td>
                        </tr>
                        <tr>
                          <td>Replacement Ins. :</td>
                          <td>$ {financeData?.replacementIns}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <h5 className="mb-3 mt-4">Other Taxes & Fees </h5>

                    <Table striped bordered hover>
                      <tbody>
                        {/* <tr>
                          <td>Total Taxes & Fees :</td>
                          <td>{financeData?.totalTaxesAndFees}</td>
                        </tr> */}
                        <tr>
                          <td>Estimated Total Taxes & Fees :</td>
                          <td>$ {financeData?.totalTaxesAndFees}</td>
                        </tr>
                        <tr>
                          <td>Admin Fee :</td>
                          <td>$ {financeData?.adminFee}</td>
                        </tr>
                        <tr>
                          <td>Air Tax :</td>
                          <td>$ {financeData?.airTax}</td>
                        </tr>
                        <tr>
                          <td>Tire Tax :</td>
                          <td>$ {financeData?.tireTax}</td>
                        </tr>
                        <tr>
                          <td>Gasoline :</td>
                          <td>$ {financeData?.gasoline}</td>
                        </tr>
                        <tr>
                          <td>OMVIC Fee :</td>
                          <td>$ {financeData?.omvicFee}</td>
                        </tr>
                        <tr>
                          <td>Lien Registration Fee :</td>
                          <td>$ {financeData?.lienRegistrationFee}</td>
                        </tr>
                        {financeData?.cashFinanceLease === 'Lease' && (
                          <tr>
                            <td>Vehicle Drop off Fee :</td>
                            <td>$ {financeData?.vehicleDropOffFee}</td>
                          </tr>
                        )}
                        <tr>
                          <td>License fee :</td>
                          <td>$ {financeData?.licenseFee}</td>
                        </tr>
                        {/* <tr>
                          <td>Sticker fee :</td>
                          <td>{financeData?.stickerFee}</td>
                        </tr> */}
                        <tr>
                          <td>Other :</td>
                          <td>$ {financeData?.other}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <h5 className="mb-3 mt-4">Trade-In Information</h5>

                    <Table striped bordered hover>
                      <tbody>
                        <tr>
                          <td>Trade In :</td>
                          <td>{financeData?.tradeIn}</td>
                        </tr>
                        <tr>
                          <td>Estimated Trade Value :</td>
                          <td>$ {financeData?.estimatedTradeValue}</td>
                        </tr>
                        <tr>
                          <td>Trade Value Lien :</td>
                          <td>$ {financeData?.tradeValueLien}</td>
                        </tr>
                        <tr>
                          <td>Net Trade In :</td>
                          <td>$ {financeData?.totalEstimatedTradeInValue}</td>
                        </tr>
                        <tr>
                          <td>Year :</td>
                          <td>{financeData?.tradeInYear}</td>
                        </tr>
                        <tr>
                          <td>Make :</td>
                          <td>{financeData?.tradeInMake}</td>
                        </tr>
                        <tr>
                          <td>Model :</td>
                          <td>{financeData?.tradeInModel}</td>
                        </tr>
                        <tr>
                          <td>Trim :</td>
                          <td>{financeData?.tradeInTrim}</td>
                        </tr>
                        <tr>
                          <td>Mileage :</td>
                          <td>{financeData?.tradeInMileage} KM</td>
                        </tr>

                        {/* <tr>
                          <td>Net Trade-in :</td>
                          <td>{financeData?.netTradeIn}</td>
                        </tr> */}
                      </tbody>
                    </Table>
                    {financeData?.cashFinanceLease === 'Lease' && (
                      <>
                        <h5 className="mb-3 mt-4">
                          Security Deposit :$ {financeData?.securityDeposit}{' '}
                        </h5>
                      </>
                    )}
                    {financeData?.cashFinanceLease === 'Cash' && (
                      <>
                        <h5 className="mb-3 mt-4">Sales Tax</h5>

                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>Tax :</td>
                              <td>{financeData?.tax}</td>
                            </tr>
                            <tr>
                              <td>Tax Rate :</td>
                              <td>{financeData?.taxRate} %</td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    )}
                    {financeData?.cashFinanceLease === 'Finance' && (
                      <h5 className="mb-3 mt-4">Downpayment : $ {financeData?.downpayment} </h5>
                    )}
                    {['Finance', 'Lease'].includes(financeData?.cashFinanceLease) && (
                      <>
                        <h5 className="mb-3 mt-4">Terms, Rates & Sales Tax</h5>

                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>Frequency :</td>
                              <td>{financeData?.frequency} </td>
                            </tr>
                            <tr>
                              <td>Terms In Years :</td>
                              <td>{financeData?.termsInYears} Years</td>
                            </tr>
                            <tr>
                              <td>Interest Rate :</td>
                              <td>{financeData?.interestRate} %</td>
                            </tr>
                            <tr>
                              <td>Tax :</td>
                              <td>{financeData?.tax}</td>
                            </tr>
                            <tr>
                              <td>Tax Rate :</td>
                              <td>{financeData?.taxRate} %</td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    )}

                    {/* <h5 className="mb-3 mt-4">Frequency : {financeData?.frequency} </h5> */}
                    <h5 className=" mt-4">Note : </h5>
                    <p>{financeData?.note}</p>
                    {financeData?.cashFinanceLease === 'Cash' && (
                      <>
                        <h5 className="mb-3 mt-4">iCarDeal Summary</h5>

                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>Total Sale Price including Freight</td>
                              <td>
                                {' '}
                                ${' '}
                                {(isValidNumber(financeData?.salePrice)
                                  ? parseFloat(financeData?.salePrice)
                                  : 0) +
                                  (isValidNumber(financeData?.rebate)
                                    ? parseFloat(financeData?.rebate)
                                    : 0) +
                                  (isValidNumber(financeData?.discount)
                                    ? parseFloat(financeData?.discount)
                                    : 0)}
                              </td>
                            </tr>
                            <tr>
                              <td>Less Rebate & Discount :</td>
                              <td>
                                {' '}
                                {(isValidNumber(financeData?.rebate)
                                  ? parseFloat(financeData?.rebate)
                                  : 0) +
                                  (isValidNumber(financeData?.discount)
                                    ? parseFloat(financeData?.discount)
                                    : 0)}
                              </td>
                            </tr>
                            <tr>
                              <td>Add Extras, Protections & Warranties :</td>
                              <td>
                                ${' '}
                                {(
                                  (isValidNumber(financeData?.totalExtras)
                                    ? parseFloat(financeData?.totalExtras)
                                    : 0) +
                                  (isValidNumber(financeData?.estimatedProtectionPackagesAndExtras)
                                    ? parseFloat(financeData?.estimatedProtectionPackagesAndExtras)
                                    : 0) +
                                  (isValidNumber(financeData?.totalWarranties)
                                    ? parseFloat(financeData?.totalWarranties)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>Add Insurance:</td>
                              <td>
                                {' '}
                                $
                                {isValidNumber(financeData?.totalInsurance)
                                  ? parseFloat(financeData?.totalInsurance).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Add Estimated Fees & Taxes:</td>
                              <td>
                                {' '}
                                ${' '}
                                {isValidNumber(financeData?.totalTaxesAndFees)
                                  ? parseFloat(financeData?.totalTaxesAndFees).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Net Trade (Value less lien):</td>
                              <td>
                                {' '}
                                ${' '}
                                {(isValidNumber(financeData?.estimatedTradeValue)
                                  ? parseFloat(financeData?.estimatedTradeValue)
                                  : 0) -
                                  (isValidNumber(financeData?.tradeValueLien)
                                    ? parseFloat(financeData?.tradeValueLien)
                                    : 0)}
                              </td>
                            </tr>
                            <tr>
                              <td> Vehicle Price Less Net Trade-In:</td>
                              <td>
                                {' '}
                                ${' '}
                                {(
                                  (isValidNumber(financeData?.salePrice)
                                    ? parseFloat(financeData?.salePrice)
                                    : 0) +
                                  (isValidNumber(financeData?.estimatedProtectionPackagesAndExtras)
                                    ? parseFloat(financeData?.estimatedProtectionPackagesAndExtras)
                                    : 0) +
                                  (isValidNumber(financeData?.totalWarranties)
                                    ? parseFloat(financeData?.totalWarranties)
                                    : 0) +
                                  (isValidNumber(financeData?.totalExtras)
                                    ? parseFloat(financeData?.totalExtras)
                                    : 0) +
                                  (isValidNumber(financeData?.totalInsurance)
                                    ? parseFloat(financeData?.totalInsurance)
                                    : 0) +
                                  (isValidNumber(financeData?.totalTaxesAndFees)
                                    ? parseFloat(financeData?.totalTaxesAndFees)
                                    : 0) -
                                  (isValidNumber(financeData?.estimatedTradeValue)
                                    ? parseFloat(financeData?.estimatedTradeValue)
                                    : 0) +
                                  (isValidNumber(financeData?.tradeValueLien)
                                    ? parseFloat(financeData?.tradeValueLien)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {financeData?.tax === 'GST' ? (
                                  <span>GST on Difference</span>
                                ) : financeData?.tax === 'PST' ? (
                                  <span>PST on Difference</span>
                                ) : financeData?.tax === 'HST' ? (
                                  <span>HST on Difference</span>
                                ) : (
                                  <span>Tax Exempted</span>
                                )}
                              </td>
                              <td>
                                {' '}
                                $
                                {(
                                  (((isValidNumber(financeData?.salePrice)
                                    ? parseFloat(financeData?.salePrice)
                                    : 0) +
                                    (isValidNumber(
                                      financeData?.estimatedProtectionPackagesAndExtras,
                                    )
                                      ? parseFloat(
                                          financeData?.estimatedProtectionPackagesAndExtras,
                                        )
                                      : 0) +
                                    (isValidNumber(financeData?.totalWarranties)
                                      ? parseFloat(financeData?.totalWarranties)
                                      : 0) +
                                    (isValidNumber(financeData?.totalExtras)
                                      ? parseFloat(financeData?.totalExtras)
                                      : 0) +
                                    (isValidNumber(financeData?.totalInsurance)
                                      ? parseFloat(financeData?.totalInsurance)
                                      : 0) +
                                    (isValidNumber(financeData?.totalTaxesAndFees)
                                      ? parseFloat(financeData?.totalTaxesAndFees)
                                      : 0) -
                                    (isValidNumber(financeData?.estimatedTradeValue)
                                      ? parseFloat(financeData?.estimatedTradeValue)
                                      : 0)) /
                                    100) *
                                  (isValidNumber(financeData?.taxRate)
                                    ? parseFloat(financeData?.taxRate)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>Total Amount Due:</td>
                              <td>$ {financeData?.totalPurchasePrice}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    )}
                    {financeData?.cashFinanceLease === 'Finance' && (
                      <>
                        <h5 className="mb-3 mt-4">iCarFinance Summary</h5>

                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>
                                {isValidNumber(financeData?.termsInYears)
                                  ? financeData?.frequency === 'Monthly'
                                    ? `${Number(financeData?.termsInYears) * 12} Monthly  Payments`
                                    : financeData?.frequency === 'Weekly'
                                    ? `${Number(financeData?.termsInYears) * 52} Weekly Payments`
                                    : financeData?.frequency === 'Semi Monthly'
                                    ? `${Number(financeData?.termsInYears) * 24} in ${
                                        Number(financeData?.termsInYears) * 12
                                      } Monthly Payments`
                                    : financeData?.frequency === 'Bi Weekly'
                                    ? `${Number(financeData?.termsInYears) * 26} Bi Weekly Payment`
                                    : 0
                                  : null}{' '}
                              </td>
                              <td>$ {financeData?.monthlyPayment}</td>
                            </tr>
                            <tr>
                              <td>Cost of Borrowing :</td>
                              <td>
                                {(
                                  (isValidNumber(financeData?.monthlyPayment)
                                    ? parseFloat(financeData?.monthlyPayment)
                                    : 0) *
                                    (isValidNumber(financeData?.numberOfPayments)
                                      ? parseFloat(financeData?.numberOfPayments)
                                      : 0) -
                                  (isValidNumber(financeData?.totalAmountFinanced)
                                    ? parseFloat(financeData?.totalAmountFinanced)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>Total of All Payments :</td>
                              <td>
                                {' '}
                                ${' '}
                                {(
                                  (isValidNumber(financeData?.monthlyPayment)
                                    ? parseFloat(financeData?.monthlyPayment)
                                    : 0) *
                                  (isValidNumber(financeData?.numberOfPayments)
                                    ? parseFloat(financeData?.numberOfPayments)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>First Payment Date:</td>
                              <td>{new Date().toLocaleDateString()}</td>
                            </tr>
                            <tr>
                              <td>Total Sale Price including Freight:</td>
                              <td>
                                ${' '}
                                {(isValidNumber(financeData?.salePrice)
                                  ? parseFloat(financeData?.salePrice)
                                  : 0) +
                                  (isValidNumber(financeData?.rebate)
                                    ? parseFloat(financeData?.rebate)
                                    : 0) +
                                  (isValidNumber(financeData?.discount)
                                    ? parseFloat(financeData?.discount)
                                    : 0)}
                              </td>
                            </tr>
                            <tr>
                              <td>Less Rebate & Discount:</td>
                              <td>
                                {' '}
                                {(isValidNumber(financeData?.rebate)
                                  ? parseFloat(financeData?.rebate)
                                  : 0) +
                                  (isValidNumber(financeData?.discount)
                                    ? parseFloat(financeData?.discount)
                                    : 0)}
                              </td>
                            </tr>
                            <tr>
                              <td>Add Extras, Protections & Warranties:</td>
                              <td>
                                ${' '}
                                {(
                                  (isValidNumber(financeData?.totalExtras)
                                    ? parseFloat(financeData?.totalExtras)
                                    : 0) +
                                  (isValidNumber(financeData?.estimatedProtectionPackagesAndExtras)
                                    ? parseFloat(financeData?.estimatedProtectionPackagesAndExtras)
                                    : 0) +
                                  (isValidNumber(financeData?.totalWarranties)
                                    ? parseFloat(financeData?.totalWarranties)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>Add Insurance:</td>
                              <td>
                                {' '}
                                $
                                {isValidNumber(financeData?.totalInsurance)
                                  ? parseFloat(financeData?.totalInsurance).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Add Estimated Fees & Taxes:</td>
                              <td>
                                ${' '}
                                {isValidNumber(financeData?.totalTaxesAndFees)
                                  ? parseFloat(financeData?.totalTaxesAndFees).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Net Trade (Value less lien):</td>
                              <td>
                                {' '}
                                ${' '}
                                {(isValidNumber(financeData?.estimatedTradeValue)
                                  ? parseFloat(financeData?.estimatedTradeValue)
                                  : 0) -
                                  (isValidNumber(financeData?.tradeValueLien)
                                    ? parseFloat(financeData?.tradeValueLien)
                                    : 0)}
                              </td>
                            </tr>
                            <tr>
                              <td> Vehicle Price Less Net Trade-In:</td>
                              <td>
                                ${' '}
                                {(
                                  (isValidNumber(financeData?.salePrice)
                                    ? parseFloat(financeData?.salePrice)
                                    : 0) +
                                  (isValidNumber(financeData?.estimatedProtectionPackagesAndExtras)
                                    ? parseFloat(financeData?.estimatedProtectionPackagesAndExtras)
                                    : 0) +
                                  (isValidNumber(financeData?.totalWarranties)
                                    ? parseFloat(financeData?.totalWarranties)
                                    : 0) +
                                  (isValidNumber(financeData?.totalExtras)
                                    ? parseFloat(financeData?.totalExtras)
                                    : 0) +
                                  (isValidNumber(financeData?.totalInsurance)
                                    ? parseFloat(financeData?.totalInsurance)
                                    : 0) +
                                  (isValidNumber(financeData?.totalTaxesAndFees)
                                    ? parseFloat(financeData?.totalTaxesAndFees)
                                    : 0) -
                                  (isValidNumber(financeData?.estimatedTradeValue)
                                    ? parseFloat(financeData?.estimatedTradeValue)
                                    : 0) +
                                  (isValidNumber(financeData?.tradeValueLien)
                                    ? parseFloat(financeData?.tradeValueLien)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {' '}
                                {financeData?.tax === 'GST' ? (
                                  <span>GST on Difference</span>
                                ) : financeData?.tax === 'PST' ? (
                                  <span>PST on Difference</span>
                                ) : financeData?.tax === 'HST' ? (
                                  <span>HST on Difference</span>
                                ) : (
                                  <span>Tax Exempted</span>
                                )}
                                :
                              </td>
                              <td>
                                $
                                {(
                                  (((isValidNumber(financeData?.salePrice)
                                    ? parseFloat(financeData?.salePrice)
                                    : 0) +
                                    (isValidNumber(
                                      financeData?.estimatedProtectionPackagesAndExtras,
                                    )
                                      ? parseFloat(
                                          financeData?.estimatedProtectionPackagesAndExtras,
                                        )
                                      : 0) +
                                    (isValidNumber(financeData?.totalWarranties)
                                      ? parseFloat(financeData?.totalWarranties)
                                      : 0) +
                                    (isValidNumber(financeData?.totalExtras)
                                      ? parseFloat(financeData?.totalExtras)
                                      : 0) +
                                    (isValidNumber(financeData?.totalInsurance)
                                      ? parseFloat(financeData?.totalInsurance)
                                      : 0) +
                                    (isValidNumber(financeData?.totalTaxesAndFees)
                                      ? parseFloat(financeData?.totalTaxesAndFees)
                                      : 0) -
                                    (isValidNumber(financeData?.estimatedTradeValue)
                                      ? parseFloat(financeData?.estimatedTradeValue)
                                      : 0)) /
                                    100) *
                                  (isValidNumber(financeData?.taxRate)
                                    ? parseFloat(financeData?.taxRate)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>Amount to be Financed:</td>
                              <td>$ {financeData?.totalPurchasePrice}</td>
                            </tr>
                            <tr>
                              <td>Amount Due on Delivery (Downpayment):</td>
                              <td>
                                {' '}
                                ${' '}
                                {isValidNumber(financeData?.downpayment)
                                  ? parseFloat(financeData?.downpayment).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Total Interest Paid:</td>
                              <td>
                                ${' '}
                                {(
                                  (isValidNumber(financeData?.monthlyPayment)
                                    ? parseFloat(financeData?.monthlyPayment)
                                    : 0) *
                                    (isValidNumber(financeData?.numberOfPayments)
                                      ? parseFloat(financeData?.numberOfPayments)
                                      : 0) -
                                  (isValidNumber(financeData?.totalAmountFinanced)
                                    ? parseFloat(financeData?.totalAmountFinanced)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>Total Amount to be Financed:</td>
                              <td>
                                {' '}
                                ${' '}
                                {(
                                  (isValidNumber(financeData?.totalPurchasePrice)
                                    ? parseFloat(financeData?.totalPurchasePrice)
                                    : 0) -
                                  (isValidNumber(financeData?.downpayment)
                                    ? parseFloat(financeData?.downpayment).toFixed(2)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    )}
                    {financeData?.cashFinanceLease === 'Lease' && (
                      <>
                        <h5 className="mb-3 mt-4">iCarLease Summary</h5>

                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td>
                                {isValidNumber(financeData?.termsInYears)
                                  ? financeData?.frequency === 'Monthly'
                                    ? `${
                                        Number(financeData?.termsInYears) * 12
                                      } Base Monthly  Payments`
                                    : financeData?.frequency === 'Weekly'
                                    ? `${
                                        Number(financeData?.termsInYears) * 52
                                      } Base Weekly Payments`
                                    : financeData?.frequency === 'Semi Monthly'
                                    ? `${Number(financeData?.termsInYears) * 24} in ${
                                        Number(financeData?.termsInYears) * 12
                                      } Base Monthly Payments`
                                    : formData.frequency === 'Bi Weekly'
                                    ? `${
                                        Number(financeData?.termsInYears) * 26
                                      } Base Bi Weekly Payment`
                                    : 0
                                  : null}{' '}
                                :
                              </td>
                              <td>$ {financeData?.baseMonthlyPaymentHonda}</td>
                            </tr>
                            <tr>
                              <td> Monthly Sales Tax {`@ ${financeData?.taxRate} %`} :</td>
                              <td> $ {financeData?.taxBaseMonthlyPaymentHonda}</td>
                            </tr>
                            <tr>
                              <td> Monthly Interest {`@ ${financeData?.interestRate} %`} :</td>
                              <td>$ {financeData?.monthlyInterestHonda}</td>
                            </tr>
                            <tr>
                              <td>
                                {isValidNumber(financeData?.termsInYears)
                                  ? financeData?.frequency === 'Monthly'
                                    ? `${Number(financeData?.termsInYears) * 12} Monthly  Payments`
                                    : financeData?.frequency === 'Weekly'
                                    ? `${Number(financeData?.termsInYears) * 52} Weekly Payments`
                                    : financeData?.frequency === 'Semi Monthly'
                                    ? `${Number(financeData?.termsInYears) * 24} in ${
                                        Number(financeData?.termsInYears) * 12
                                      } Monthly Payments`
                                    : financeData?.frequency === 'Bi Weekly'
                                    ? `${Number(financeData?.termsInYears) * 26} Bi Weekly Payment`
                                    : 0
                                  : null}{' '}
                                :
                              </td>
                              <td>$ {financeData?.monthlyPaymentHonda}</td>
                            </tr>
                            <tr>
                              <td>Total Monthly Payment:</td>
                              <td> $ {financeData?.totalMonthlyPaymentHonda}</td>
                            </tr>
                            <tr>
                              <td>Total Monthly Payment (Without Tax):</td>
                              <td> $ {financeData?.totalMonthlyPaymentHondaWithouTax}</td>
                            </tr>
                            <tr>
                              <td> Cost of Borrowing including Interest & Fees:</td>
                              <td>$ {financeData?.totalCostOfBorrowingHonda}</td>
                            </tr>
                            <tr>
                              <td>Due on Delivery:</td>
                              <td>$ {financeData?.dueUponDeliveryHonda}</td>
                            </tr>
                            <tr>
                              <td>Total Amount to be Leased:</td>
                              <td>
                                {' '}
                                ${' '}
                                {(isValidNumber(financeData?.totalMonthlyPaymentHonda)
                                  ? parseFloat(financeData?.totalMonthlyPaymentHonda)
                                  : 0) +
                                  (isValidNumber(financeData?.dueUponDeliveryHonda)
                                    ? parseFloat(financeData?.dueUponDeliveryHonda)
                                    : 0) -
                                  ((isValidNumber(financeData?.monthlyPaymentHonda)
                                    ? parseFloat(financeData?.monthlyPaymentHonda)
                                    : 0) +
                                    (isValidNumber(financeData?.securityDeposit)
                                      ? parseFloat(financeData?.securityDeposit)
                                      : 0))}
                              </td>
                            </tr>
                            <tr>
                              <td>MSRP (Capital Cost):</td>
                              <td>
                                {' '}
                                ${' '}
                                {isValidNumber(financeData?.leaseSalePrice)
                                  ? parseFloat(financeData?.leaseSalePrice)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Residual Value (Based on MSRP):</td>
                              <td>
                                $
                                {isValidNumber(financeData?.buyOut)
                                  ? parseFloat(financeData?.buyOut).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Less Capital Cost Reduction:</td>
                              <td>
                                ${' '}
                                {isValidNumber(financeData?.capitalCostReduction)
                                  ? parseFloat(financeData?.capitalCostReduction).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Add Extras, Protections & Maintenance:</td>
                              <td>
                                {' '}
                                ${' '}
                                {(
                                  (isValidNumber(financeData?.totalExtras)
                                    ? parseFloat(financeData?.totalExtras)
                                    : 0) +
                                  (isValidNumber(financeData?.estimatedProtectionPackagesAndExtras)
                                    ? parseFloat(financeData?.estimatedProtectionPackagesAndExtras)
                                    : 0) +
                                  (isValidNumber(financeData?.maintenanceAndLeaseProtection)
                                    ? parseFloat(financeData?.maintenanceAndLeaseProtection)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>Add Insurance:</td>
                              <td>
                                $
                                {isValidNumber(financeData?.totalInsurance)
                                  ? parseFloat(financeData?.totalInsurance).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Add Total Fees & Taxes:</td>
                              <td>
                                {' '}
                                ${' '}
                                {isValidNumber(financeData?.totalTaxesAndFees)
                                  ? parseFloat(financeData?.totalTaxesAndFees).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Lease Summary Subtotal:</td>
                              <td>
                                {' '}
                                ${' '}
                                {(
                                  (isValidNumber(financeData?.leaseSalePrice)
                                    ? parseFloat(financeData?.leaseSalePrice)
                                    : 0) +
                                  (isValidNumber(financeData?.estimatedProtectionPackagesAndExtras)
                                    ? parseFloat(financeData?.estimatedProtectionPackagesAndExtras)
                                    : 0) +
                                  (isValidNumber(financeData?.maintenanceAndLeaseProtection)
                                    ? parseFloat(financeData?.maintenanceAndLeaseProtection)
                                    : 0) +
                                  (isValidNumber(financeData?.totalExtras)
                                    ? parseFloat(financeData?.totalExtras)
                                    : 0) +
                                  (isValidNumber(financeData?.totalInsurance)
                                    ? parseFloat(financeData?.totalInsurance)
                                    : 0) +
                                  (isValidNumber(financeData?.totalTaxesAndFees)
                                    ? parseFloat(financeData?.totalTaxesAndFees)
                                    : 0) -
                                  (isValidNumber(financeData?.capitalCostReduction)
                                    ? parseFloat(financeData?.capitalCostReduction)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>Deduct Trade-In (if any):</td>
                              <td>
                                {' '}
                                ${' '}
                                {isValidNumber(financeData?.estimatedTradeValue)
                                  ? parseFloat(financeData?.estimatedTradeValue).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Add Lien-On Trade-In:</td>
                              <td>
                                ${' '}
                                {isValidNumber(financeData?.tradeValueLien)
                                  ? parseFloat(financeData?.tradeValueLien).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Net Trade-In Value:</td>
                              <td>
                                ${' '}
                                {(
                                  (isValidNumber(financeData?.estimatedTradeValue)
                                    ? parseFloat(financeData?.estimatedTradeValue)
                                    : 0) -
                                  (isValidNumber(financeData?.tradeValueLien)
                                    ? parseFloat(financeData?.tradeValueLien)
                                    : 0)
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {' '}
                                Due on Delivery(Includes First Month Payment, Security Deposit,
                                Downpayment, Net Trade-In & License Fee):
                              </td>
                              <td> $ {financeData?.dueUponDeliveryHonda}</td>
                            </tr>
                            <tr>
                              <td> Total Interest Paid for the Lease Term:</td>
                              <td>$ {financeData?.totalInterestPaidHonda}</td>
                            </tr>
                            <tr>
                              <td> Total Amount to be Leased:</td>
                              <td>
                                ${' '}
                                {(isValidNumber(financeData?.totalMonthlyPaymentHonda)
                                  ? parseFloat(financeData?.totalMonthlyPaymentHonda)
                                  : 0) +
                                  (isValidNumber(financeData?.dueUponDeliveryHonda)
                                    ? parseFloat(financeData?.dueUponDeliveryHonda)
                                    : 0) -
                                  ((isValidNumber(financeData?.monthlyPaymentHonda)
                                    ? parseFloat(financeData?.monthlyPaymentHonda)
                                    : 0) +
                                    (isValidNumber(financeData?.securityDeposit)
                                      ? parseFloat(financeData?.securityDeposit)
                                      : 0))}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    )}
                  </div>
                </div>
              </Col>
              <Col className="m-2">
                <Button variant="secondary" onClick={handleGoBack} className="ml-2">
                  Go Back
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  )
}

export default ViewFinance
