import React from 'react'
import CIcon from '@coreui/icons-react'

// import {BiUserPlus } from 'react-icons/bi'
import {
  cilAddressBook,
  cilMoney,
  cilSettings,
  cilPlaylistAdd,
  cilListRich,
  cilAirplay,
  cilCarAlt,
  cilInstitution,
  cilUser,
  cilUserFollow,
  cibHackhands,
  cilList,
  cilChatBubble,
} from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'MENU',
    to: '/dashboard',
    // icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
    disabled: true,
  },

  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilAirplay} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'User Management',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'User List',
        icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
        to: '/userList',
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilUserFollow} customClassName="nav-icon" />,
        name: 'Add New User',
        to: '/addNewUser',
      },
    ],
  },

  {
    component: CNavGroup,
    name: 'Dealer Management',
    icon: <CIcon icon={cilInstitution} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Dealer List',
        icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
        to: '/customerList',
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilPlaylistAdd} customClassName="nav-icon" />,
        name: 'Add New Dealer',
        to: '/addNewCustomer',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Vehicle Management',
    icon: <CIcon icon={cilCarAlt} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Vehicle Type',
        icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
        to: '/vehicleType',
      },
      {
        component: CNavItem,
        name: 'Make List',
        icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
        to: '/productList',
      },

      {
        component: CNavItem,
        name: 'Vehicle List',
        icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
        to: '/carList',
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Enquiry Management',
    to: '/enquiryManagement',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Content Management',
    icon: <CIcon icon={cibHackhands} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'About Us',
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
        to: '/aboutUs',
      },
      {
        component: CNavItem,
        name: 'Policy & Condition ',
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
        to: '/policy',
      },
      {
        component: CNavItem,
        name: 'Asterisk Info',
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
        to: '/popover',
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Appointments',
    to: '/BookingAppointment',
    icon: <CIcon icon={cilChatBubble} customClassName="nav-icon" />,
  },
   {
    component: CNavItem,
    name: 'Contact Mesages',
    to: '/contact-messages',
    icon: <CIcon icon={cilChatBubble} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'Settings',
    to: '/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
]

export default _nav
